<template>
    <el-popover placement="bottom-end"
                width="350"
                :offset="0"
                popper-class="message-popover"
                trigger="hover">
      <template #default>
        <div class="message-box">
          <div class="title">See what's new at Airlyle Fulfillment</div>
          <div class="desc">We're always making improvements to help you run your business better.</div>
        </div>
      </template>
      <template #reference>
        <div class="popover-ref-box">
          <svg-icon icon-class="layout-layout_notice" class-name="message-svg fs-24"/>
        </div>
      </template>
    </el-popover>
</template>

<script>
import {getList} from "@/api/desk/notice.js";

export default {
  name: "top-notice",
  data() {
    return {
      activeName: 'first',
      option: {
        props: {
          img: 'img',
          title: 'title',
          subtitle: 'subtitle',
          tag: 'tag',
          status: 'status'
        },
      },
      data: [],
    }
  },
  created() {
    this.getInit();
  },
  methods: {
    getCurrentDate() {
      return new Date().toLocaleDateString().split('/').map(item => {if ( item < 10 ) {return '0' + item} else {return item}}).join('-')
    },
    getInit() {
      let params = {
        releaseTime_datege: `${ this.getCurrentDate() } 00:00:00`,
        releaseTime_datelt: `${ this.getCurrentDate() } 23:59:59`,
      }
      getList(1, 10, params)
      // .then(res=>{
      //     console.log(res)
      // })
    },
    pageChange(page, done) {
      setTimeout(() => {
        this.$message.success('页码' + page)
        this.data = this.data.concat([]);
        done();
      }, 1000)
    },
  }
};
</script>

<style lang="scss">
.message-popover {
  margin-top: 0 !important;
}
</style>

<style lang="scss" scoped>
.message-box {
  padding: 5px 0 4px 12px;

  .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
  }

  .desc {
    font-weight: 400;
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
  }
}

.message-svg {
  color: #fff;
  stroke: none;
  cursor: pointer;
  vertical-align: 0;
}

.popover-ref-box{
  height: $topNavHeight;
}
:deep(.el-popover__reference-wrapper ){
  .message-svg {
    height: $topNavHeight;
    vertical-align: 0;
  }
}
</style>
