export default {
  title: 'AirlyleFulfillment',
  logoutTip: 'Exit the system, do you want to continue?',
  submitText: 'submit',
  cancelText: 'Cancel',
  search: 'Please input search content',
  menuTip: 'none menu list',
  common: {
    condition: 'condition',
    display: 'display',
    hide: 'hide'
  },
  tip: {
    select: 'Please select',
    input: 'Please input'
  },
  upload: {
    upload: 'Upload',
    tip: 'Drag files here，/',
    select:'Select File',
    file:'File',
    download:'Download attachment',
  },
  date: {
    start: 'Start date',
    end: 'End date',
    t: 'today',
    y: 'yesterday',
    n: 'nearly 7',
    a: 'whole'
  },
  form: {
    printBtn: 'print',
    mockBtn: 'mock',
    submitBtn: 'submit',
    emptyBtn: 'empty'
  },
  crud: {
    filter: {
      addBtn: 'add',
      clearBtn: 'clear',
      resetBtn: 'reset',
      cancelBtn: 'cancel',
      submitBtn: 'submit'
    },
    column: {
      name: 'name',
      hide: 'hide',
      fixed: 'fixed',
      filters: 'filters',
      sortable: 'sortable',
      index: 'index',
      width: 'width'
    },
    tipStartTitle: 'Currently selected',
    tipEndTitle: 'items',
    editTitle: 'edit',
    copyTitle: 'copy',
    addTitle: 'add',
    viewTitle: 'view',
    filterTitle: 'filter',
    showTitle: 'showTitle',
    tableItem: 'Item#',
    menu: 'Menu',
    addBtn: 'add',
    show: 'show',
    hide: 'hide',
    open: 'open',
    shrink: 'shrink',
    printBtn: 'print',
    excelBtn: 'excel',
    updateBtn: 'update',
    cancelBtn: 'cancel',
    searchBtn: 'search',
    emptyBtn: 'empty',
    menuBtn: 'menu',
    saveBtn: 'save',
    viewBtn: 'view',
    editBtn: 'edit',
    copyBtn: 'copy',
    delBtn: 'delete',
    system: 'system'
  },
  notify: {
    title: 'Notify',
    termsNotify: 'you need to agree to our Terms and Privacy',
    error: 'The network is abnormal, please try again',
  },
  login: {
    title: 'Login ',
    info: 'Welcome back!',
    emailLabel: 'Email Address',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Re-enter Password',
    tenantId: 'Please input tenantId',
    email: 'Please input email address',
    username: 'Please input username',
    password: 'Please input a password',
    confirmPassword: 'confirm password again',
    rulePassword: 'The password must be at least 6 characters long',
    wechat: 'Wechat',
    qq: 'QQ',
    github: 'github',
    gitee: 'gitee',
    phone: 'Please input a phone',
    code: 'Please input a code',
    submit: 'Login',
    userLogin: 'userLogin',
    phoneLogin: 'phoneLogin',
    thirdLogin: 'thirdLogin',
    ssoLogin: 'ssoLogin',
    msgText: 'send code',
    msgSuccess: 'reissued code',
    goForget: 'Forget Password?',
    registerTip: 'Already have an account?',
    loginTip: "Don't have account?",
    goRegister: "Get Started Now",
    goLogin: 'Log in now',
    load: 'Login,please wait....'
  },
  register: {
    firstName: 'First Name',
    firstNameError: 'Please input First Name',
    lastName: 'Last Name',
    lastNameError: 'Please input Last Name',
    ordersError: 'Please input order',
    passwordAgainError: 'Inconsistent passwords',
    shippingMonthLabel: 'How Many Orders Are You Shipping Per Month?',
    submit: 'Get Started',
    load: 'Registering,please wait....'
  },
  forget: {
    title: 'Forgot password?',
    resetTitle: 'Reset password?',
    submit: 'Send Reset Link',
    codeLabel: 'Code',
    load: 'Sending,please wait....'
  },
  reset: {
    load: 'Resetting,please wait....'
  },
  navbar: {
    info: 'info',
    logOut: 'logout',
    userinfo: 'userinfo',
    dashboard: 'dashboard',
    switchDept: 'switch dept',
    lock: 'lock',
    bug: 'none bug',
    bugs: 'bug',
    screenfullF: 'exit screenfull',
    screenfull: 'screenfull',
    language: 'language',
    notice: 'notice',
    theme: 'theme',
    color: 'color'
  },
  tagsView: {
    search: 'Search',
    menu: 'menu',
    clearCache: 'Clear Cache',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  dataSearch: {
    menuName: 'MenuName',//菜单名称
    menuNumber: 'MenuNumber',//菜单编号
    menuAlias: 'MenuAlias',//菜单别名
    addChild: 'AddChild',//新增子项
    delete: 'delete',//删除
    add: 'Add',//新增
  },
  formRule: {
    url: 'Please input right URL'
  },
  store: {
    source: 'source', //来源
    address: 'address', //地址
    status: 'status', //状态
    createtime: 'createtime', //创建日期
    operator: 'operator', //操作员
  },
  product: {
    source: 'source',
    category: 'category',
    product: 'product',
  },
  operate: {
    delete: 'Delete',//删除
    add: 'Add',//新增
    view: 'View',//查看
    edit: 'Edit',//编辑
    next: 'Next',//下一步
    cancel: 'Cancel',//取消
    confirm: 'Confirm',//确定
    back: 'back',//上一步
    save: 'save',//保存
    download: 'Download',//下载
    import: 'Import',//导入
    export: 'Export',//导出
    update_sku_stock: 'Update inventory',//更新库存
    createOrder: 'Create Order',//创建工单
    process: 'Process',//处理
    reply: 'Reply',//追加问题
    nodata: 'No data',//
    reset: 'Reset',
    filter: 'Filter',
    create: 'Create',
    upload: 'Upload',
  },
  list: {
    allStore: 'All Store',
    allDestinations: 'All Destinations',
    remark: 'Remark',
    ec: 'ECang',
    chooseCarriers: 'Choose Carriers',
    confirmShipment: 'Confirm Shipment',
    orderInformation: 'Order Information',
  },
  sku_inventory: {
    ShopifySKU: 'Shopify SKU',
    WarehouseSKU: 'Warehouse SKU',
    ProductName: 'Product Name',
    StoreName: "Store Name",
    WarehouseCode: "Warehouse Code",
    InboundReceiving: "Inbound Receiving",
    PutAway: "Put Away",
    AvailableforSale: "Available for Sale",
    PiCanSaleDays: 'Available days',
    Defective: "Defective",
    AwaitingOutbound: "Awaiting Outbound",
    Shipped: "Shipped",
    Notes: "Notes",
  },
  task_list: {
    tab: {
      all: 'All',
      // awaiting: 'Awaiting processing',
      awaiting: 'Pending',
      processing: 'In process',
      closed: 'Closed',
    },
    btn: {
      reopen: 'Reopen',
      reply: 'Reply',
      refuse: 'Refuse',
      complete: 'Complete',
      ticket: 'Close Ticket',
    },
    taskCode: 'Task ID',
    createUserName: 'Creator',
    merchantCode: 'Client ID',
    merchantName: 'Client Name',
    typeName: 'Task Type',
    priorityName: 'Priority',
    statusName: 'Status',
    estimatedCompletionTime: 'Estimated Completion Date',
    completeTime: 'Completion Date',
    age: 'Age',
    createTime: 'Create Time',
    item: 'Item',
    freezeCartons: 'Freeze Cartons',
    detail: {
      tab_view: 'Task View',
      tab_time: 'Time Logs',
      tag: 'Tag',
      description: 'Description',
      setCompletionTime: 'Set the estimated processing completion time to',
      reasonForModificationTime: 'Reason for modification time',
      warehouseReply:'Warehouse reply',
      myReply:'My reply',
      businessReply:'Business reply',
    }
  },
  // 海箱追踪模块
  track:{
    createShipment:{
      MBLBookingNumber:'Please Input MBL / Booking Number'
    },
    list:{
      download_error:'None File Download'
    }
  },
  order:{
    bill:{
      verify:'Verify',
      pleaseVerified:'Please confirm the invoice is verified?',
      receivedPayment:'Received Payment',
      pleaseProofs:'Please upload the payment proofs',
      ReGeneratetheInvoice:'Re-generate the Invoice',
      pleaseReGeneratetheInvoice:'Please confirm to re-gerenrate the invoice?',
      checkPaymentProofs:'Check Payment Proofs',
      NoProofs:'No proofs at the moment',
    }
  }
}
