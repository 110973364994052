import {setToken, setRefreshToken, removeToken, removeRefreshToken, getButtonsCode} from '@/utils/auth';
import { setStore, getStore } from '@/utils/store';
import { validatenull } from '@/utils/validate';
import { deepClone } from '@/utils/util';
import {
  loginByUsername,
  loginBySocial,
  loginBySso,
  getUserInfo,
  logout,
  refreshToken,
  getButtons,
  // registerUser,
} from '@/api/user';
import { getRoutes, getTopMenu } from '@/api/system/menu';
import { formatPath } from '@/router/avue-router';
import { ElMessage } from 'element-plus';
import md5 from 'js-md5';

const user = {
  state: {
    tenantId: getStore({ name: 'tenantId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || [],
    permission: getStore({ name: 'permission' }) || {},
    roles: [],
    menuId: {},
    menu: getStore({ name: 'menu' }) || [],
    menuAll: getStore({ name: 'menuAll' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || '',
  },
  actions: {
    //根据用户名登录
    LoginByUsername({ commit }, userInfo = {}) {
      return new Promise((resolve, reject) => {
        loginByUsername(
          userInfo.tenantId,
          userInfo.deptId,
          userInfo.roleId,
          userInfo.username,
          md5(userInfo.password),
          userInfo.type,
          userInfo.key,
          userInfo.code
        )
          .then(res => {
            const data = res.data;
            if (data.error_description) {
              ElMessage({
                message: data.error_description,
                type: 'error',
              });
            } else {
              commit('SET_TOKEN', data.access_token);
              commit('SET_REFRESH_TOKEN', data.refresh_token);
              commit('SET_TENANT_ID', data.tenant_id);
              commit('SET_USER_INFO', data);
              commit('DEL_ALL_TAG');
              commit('CLEAR_LOCK');
            }
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //根据第三方信息登录
    LoginBySocial({ commit }, userInfo) {
      return new Promise(resolve => {
        loginBySocial(userInfo.tenantId, userInfo.source, userInfo.code, userInfo.state).then(
          res => {
            const data = res.data;
            if (data.error_description) {
              ElMessage({
                message: data.error_description,
                type: 'error',
              });
            } else {
              commit('SET_TOKEN', data.access_token);
              commit('SET_REFRESH_TOKEN', data.refresh_token);
              commit('SET_USER_INFO', data);
              commit('SET_TENANT_ID', data.tenant_id);
              commit('DEL_ALL_TAG');
              commit('CLEAR_LOCK');
            }
            resolve();
          }
        );
      });
    },
    //根据单点信息登录
    LoginBySso({ commit }, userInfo) {
      return new Promise(resolve => {
        loginBySso(userInfo.state, userInfo.code).then(res => {
          const data = res.data;
          if (data.error_description) {
            ElMessage({
              message: data.error_description,
              type: 'error',
            });
          } else {
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_USER_INFO', data);
            commit('SET_TENANT_ID', data.tenant_id);
            commit('DEL_ALL_TAG');
            commit('CLEAR_LOCK');
          }
          resolve();
        });
      });
    },
    // RegisterUser({ commit }, userInfo = {}) {
    //   return new Promise((resolve, reject) => {
    //     registerUser(
    //       userInfo.tenantId,
    //       userInfo.name,
    //       userInfo.account,
    //       userInfo.password,
    //       userInfo.phone,
    //       userInfo.email
    //     ).then(res => {
    //       const data = res.data;
    //       if (data.error_description) {
    //         ElMessage({
    //           message: data.error_description,
    //           type: 'error',
    //         });
    //         reject(data.error_description);
    //       } else {
    //         commit('SET_TOKEN', data.access_token);
    //         commit('SET_REFRESH_TOKEN', data.refresh_token);
    //         commit('SET_USER_INFO', data);
    //         commit('SET_TENANT_ID', data.tenant_id);
    //         commit('DEL_ALL_TAG');
    //         commit('CLEAR_LOCK');
    //       }
    //       resolve();
    //     });
    //   });
    // },
    //获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            const data = res.data.data;
            commit('SET_ROLES', data.roles);
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //刷新token
    RefreshToken({ state, commit }, userInfo) {
      return new Promise((resolve, reject) => {
        refreshToken(
          state.refreshToken,
          state.tenantId,
          !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id,
          !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id
        )
          .then(res => {
            const data = res.data;
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESH_TOKEN', data.refresh_token);
            commit('SET_USER_INFO', data);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_MENU_ALL_NULL', []);
            commit('SET_MENU', []);
            commit('SET_ROLES', []);
            commit('DEL_ALL_TAG', []);
            commit('CLEAR_LOCK');

            commit('SET_TAG_LIST', []);
            commit('SET_PERMISSION',[]);
            removeToken();
            removeRefreshToken();
            removeToken();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //注销session
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_MENU_ALL_NULL', []);
        commit('SET_MENU', []);
        commit('SET_ROLES', []);
        commit('SET_TAG_LIST', []);
        commit('DEL_ALL_TAG');
        commit('CLEAR_LOCK');
        removeToken();
        removeRefreshToken();
        removeToken();
        resolve();
      });
    },
    // 获取顶部菜单
    GetTopMenu() {
      return new Promise(resolve => {
        getTopMenu().then(res => {
          const data = res.data.data || [];
          resolve(data);
        });
      });
    },
    //获取系统菜单
    GetMenu({ commit, dispatch }, tenantId) {
      return new Promise(resolve => {
        getRoutes(tenantId).then(res => {
          const data = res.data.data;
          let menu = deepClone(data);
          if(Array.isArray(menu)){
            menu.forEach(ele => formatPath(ele, true));
            commit('SET_MENU', menu);
            commit('SET_MENU_ALL', menu);
            console.log('获取系统菜单-----',menu);
            dispatch('GetButtons')
            .then(buttons =>{
              resolve({
                menu,
                buttons
              })
            })
          }
          else{
            resolve({
              menu,
              buttons:[]
            })
          }
          // resolve(menu);
        });
      });
    },
    //获取系统按钮
    GetButtons({ commit }) {
      return new Promise(resolve => {
        getButtons().then(res => {
          const data = res.data.data;
          commit('SET_PERMISSION', data);
          resolve(data);
        });
      });
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({ name: 'token', content: state.token });
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken);
      state.refreshToken = refreshToken;
      setStore({ name: 'refreshToken', content: state.refreshToken });
    },
    SET_MENU_ID(state, menuId) {
      state.menuId = menuId;
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId;
      setStore({ name: 'tenantId', content: state.tenantId });
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = '/img/bg/img-logo.png';
      }
      state.userInfo = userInfo;
      setStore({ name: 'userInfo', content: state.userInfo });
    },
    SET_MENU_ALL: (state, menuAll) => {
      // let menu = state.menuAll;
      // menuAll.forEach(ele => {
      //   let index = menu.findIndex(item => item.path === ele.path);
      //   if (index === -1) {
      //     menu.push(ele);
      //   } else {
      //     menu[index] = ele;
      //   }
      // });
      state.menuAll = menuAll;
      setStore({ name: 'menuAll', content: state.menuAll });
    },
    SET_MENU_ALL_NULL: state => {
      state.menuAll = [];
      setStore({ name: 'menuAll', content: state.menuAll });
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
      setStore({ name: 'menu', content: state.menu });
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSION: (state, permission) => {
      let result = getButtonsCode(permission);
      state.permission = {};
      result.forEach(ele => {
        state.permission[ele] = true;
      });
      setStore({ name: 'permission', content: state.permission, type: 'session' });
    },
  },
};
export default user;
