<template>
    <div>
        <div class="tip-box"><svg-icon icon-class="tip" class-name="tip-icon"/><span>Drag and drop movable fields in list order</span></div>
        <div class="row">
            <div class="col-3">
                <h4 class="title"><span>List display field</span></h4>
                <div class="list-group-box">
                    <draggable
                        class="list-group"
                        item-key="field"
                        handle=".handle"
                        :component-data="{name: 'flip-list', type: 'transition-group' }"
                        :list="list1"
                        v-bind="dragOptions"
                        @change="logTop"
                    >
                        <template #item="{ element, index }">
                            <div class="list-group-item" :class="element.isDrag?'not-move-item':'handle'" :key="index" >
                                <el-tooltip v-if="element.isDrag" class="item" effect="dark" content="Necessary field, cannot be cancelled and Immovable position" placement="top">
                                    <div>{{ element.label }}</div>
                                </el-tooltip>
                                <div v-else>{{ element.label }}</div>
                            </div>
                        </template>
                    </draggable>
                </div>
            </div>
            <div class="col-3">
                <h4 class="title"><span>List hidden field</span></h4>
                <div class="list-group-box">
                    <draggable
                        class="list-group"
                        item-key="field"
                        handle=".handle"
                        :component-data="{name: 'flip-list', type: 'transition-group' }"
                        :list="list2"
                        v-bind="dragOptions"
                        @onSort="sort"
                        @change="logBottom"
                    >
                        <template #item="{ element, index }">
                            <div class="list-group-item list-group-item-hide" :class="element.isDrag?'not-move-item':'handle'" :key="index" >
                                <el-tooltip v-if="element.isDrag" class="item" effect="dark" content="Necessary field, cannot be cancelled and Immovable position" placement="top">
                                    <div>{{ element.label }}</div>
                                </el-tooltip>
                                <div v-else>{{ element.label }}</div>
                            </div>
                        </template>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Draggable from "vuedraggable";
import { cloneDeep } from "lodash-es";

export default {
    props: ['options'],
    name: 'set-column',
    components: {
        Draggable
    },
    data() {
        return {
            drag: false,
            list1:[],
            list2:[],
            source:[]
        }
    },
    computed:{
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    mounted() {
        this.source = cloneDeep(this.options.column)
        // let columns = this.source.map((item,index)=>{
        //     item.order = index;
        //     return item
        // });
        console.log(this.source);
        this.list1 = this.source.filter(item => {
            return !!item.hide === false
        }).map((item,index)=>{
            item.order = index;
            return item
        });

        this.list2 = this.source.filter(item => {
            return item.hide === true
        })
    },
    methods: {
        sort() {
            this.list1 = this.list1.sort((a, b) => ( a.order -  b.order));
            this.list2 = this.list2.sort((a, b) => ( a.order -  b.order));
        },
        indexSort(evt_type){
            if(evt_type.newIndex > evt_type.oldIndex){
                let num = evt_type.newIndex - 1;
                if(this.list1[num] && this.list1[num].isDrag){
                    evt_type.element.order = num;
                    this.list1[num].order = evt_type.newIndex;
                }else{
                    evt_type.element.order = evt_type.newIndex;
                }
            }else{
                let num = evt_type.newIndex + 1;
                if(this.list1[num] && this.list1[num].isDrag){
                    evt_type.element.order = num;
                    this.list1[num].order = evt_type.newIndex;
                }else{
                    evt_type.element.order = evt_type.newIndex;
                }
            }
        },
        logTop(evt) {
            console.log(evt)
            if (evt.added) {
                evt.added.element.hide = false;
                this.indexSort(evt.added);
            }
            if (evt.removed) {
                evt.removed.element.hide = true;
            }
            if(evt.moved){
                this.indexSort(evt.moved);
            }
            this.sort();
        },
        logBottom(evt) {
            console.log(evt)
            if (evt.added) {
                evt.added.element.hide = true;
            }
            if (evt.removed) {
                evt.removed.element.hide = false;
            }
            // if(evt.moved){
            //     evt.moved.element.order = evt.moved.newIndex;
            // }
            this.sort();
        }
    }
}

</script>

<style scoped lang="scss">

.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.col-3 {
    width: 100%;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h4.title{
    color: #252B84;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    position: relative;
    margin: 0;
    span{
        position: relative;
        z-index: 2;
    }
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 1px;
        width: 40px;
        height: 7px;
        background: #E18830;
        z-index: 1;
    }
}

.list-group-box {
    display: flex;
    flex-direction: row;
    margin: 30px 0 20px;
}

.list-group {
    min-width: 200px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-wrap: wrap;
    //flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    margin-right: -10px;
    min-height: 50px;

}

.list-group-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    //flex: 0 0 18%;
    min-width: 160px;
    padding: 0 10px;
    background-color: #7379D9;
    color: #fff;
    margin-bottom: 10px;
    //margin-right: calc(10% / 4);
    margin-right: 10px;
    text-align: center;
    cursor: move;
    >div{
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        line-height: 40px;
    }
    /* 去除每行尾多余的边距 */
    //&:nth-child(5n) {
    //    margin-right: 0;
    //}
}
.not-move-item{
    background-color: #E0E2FF;
    color: #686ECB;
    cursor: no-drop;
}
.list-group-item-hide{
    background-color: #EFEFEF;
    color: #333333;
}


.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.tip-box{
    width: 100%;
    background: #F4F4F4;
    margin: 0 auto 45px;
    height: 32px;
    color: #888888;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .tip-icon{
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }
}
</style>
