import {getStore, setStore} from '@/utils/store'
import {getDictionary} from "@/api/system/dictbiz";

const businessDict = {
  state: {
    // businessDict: getStore({name: 'businessDict'}) || {},
    businessDict: {},
  },
  actions: {
    BusinessDict({commit},codeName) {
      return new Promise((resolve, reject) => {
        getDictionary({code: codeName}).then(res => {
          commit('SET_BUSINESS_DICT', {
            name:codeName,
            data:res.data.data
          });
          console.log(res.data)
          let data = res.data.data.map(item => {
            return {
              label: item.dictKey,
              value: item.dictValue,
            };
          });
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  mutations: {
    SET_BUSINESS_DICT: (state, data) => {
      state.businessDict[data.name] = data.data.map(item => {
        return {
          label: item.dictKey,
          value: item.dictValue,
        };
      });
      setStore({name: 'businessDict',type: 'session', content: state.businessDict})
    },
  }

};

export default businessDict;
