<template>
  <div>
    <el-dialog title="Guide"
               append-to-body
               :fullscreen="true"
               @close="close"
               :model-value="show">
      <template #header>
        <div  class="title-box">
          <h2>Beginner's guide</h2>
          <el-button class="jump_over" type="primary" link @click="jump">jump over</el-button>
        </div>
      </template>
      <el-carousel trigger="click"
                   :height="height"
                   :autoplay="false"
                   :loop="false"
                   ref="carousel_guide">
        <el-carousel-item v-for="item in 7" :key="item" class="carousel-box">
          <img :src="`/img/guide/guide-store-${item}.png`" alt="">
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, nextTick, watch } from "vue";
import store from "@/store";


const carousel_guide = ref();

const height = ref();


const show = computed(()=>{
    let value = false;
    // console.log('!this.guideIsShow',!store.getters.guideIsShow);
    // 如果弹过，就不自动弹
    if ( !store.getters.guideIsShow ) {
        value = true;
    }else{
        value = store.getters.guideShow
    }
    // console.log('guideShowDialog',store.getters.guideShow);
    // console.log('watch',value);
    if(value){
        nextTick(()=>{
            carousel_guide.value && carousel_guide.value.setActiveItem(0)
        })
    }
    return value
})

const setHeight = ()=>{
    // let viewBox = window.document.getElementById('avue-view');
    let viewBox = window.document.getElementsByTagName('body')[0];
    // console.log('viewBox',viewBox);
    // console.log('viewBox',viewBox.offsetHeight);
    if(viewBox){
        height.value = viewBox.offsetHeight - 100 + 'px'
    }
}
const close = ()=>{

    store.dispatch('set_guide_show',false);

    setTimeout(()=>{
        carousel_guide.value.setActiveItem(0);
    },200)

}
const jump = ()=>{
    close();
}

setHeight();
window.addEventListener('resize',()=>{
    setHeight();
})
</script>

<style scoped lang="scss">
.carousel-box{
  //padding-top: 20px;
  text-align: center;
  img{
    width: 80%;
  }
}
:deep(.el-carousel__button){
  background-color: #333;
  opacity:0.2;
}
.title-box{
  display: flex;
  align-items: center;
  .jump_over{
    margin-left: 50px;
  }
}
</style>
