<template>
  <teleport to="body">
    <el-dialog append-to-body
               v-bind="$attrs"
               class="airlyle-wnn-dialog"
               center>
      <template #header>
        <h3>
          <slot name="title"></slot>
        </h3>
      </template>
      <slot></slot>
      <template #footer >
        <slot name="footer"></slot>
      </template>
    </el-dialog>
  </teleport>
</template>

<script>
export default {
    name: "dialog-public",
    data() {
        return {}
    },
    mounted() {

    },
    methods: {}
}
</script>

<style lang="scss">
.airlyle-wnn-dialog{
    h3{
        text-align: left;
    }
    .el-dialog__body{
        padding: 13px 43px 0;
    }
    .el-dialog__footer{
        padding: 37px 20px 50px;
    }
}
</style>
