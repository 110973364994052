<template>
  <div class="basic-container"
       :style="styleName"
       :class="`${className?className:''} ${block?'basic-container--block':''}`">
    <el-card class="basic-container__card">
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "basicContainer",
  props: {
    radius: {
      type: [ String, Number ],
      default: 0
    },
    background: {
      type: String,
      default: '#E0E6EF',
    },
    block: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    styleName() {
      return {
        borderRadius: this.setPx(this.radius),
        background: this.background,
      }
    }
  }
};
</script>

<style lang="scss">
.basic-container {
  padding: 0 0 0;
  box-sizing: border-box;

  &--block {
    height: 100%;

    .basic-container__card {
      height: 100%;
    }
  }

  &__card {
    width: 100%;
  }
}

.ui-basic {
  //height: 100%;
  // 解决因tags高度存在
  height: calc(100% - 40px);
  padding: 0 16px 8px;
  .el-card {
    height: 100%;
  }

  .el-card__body {
    padding: 0;
    height: 100%;
  }
}
</style>
