<template>
  <div>
    <div class="fixed-footer">

      <el-tooltip class="item"
                  effect="dark"
                  content="Guide Video"
                  placement="top">
        <div @click="guideClick">
          <div>
<!--            <icon class="iconfont icon-xinshouyindao"></icon>-->
            <i class="iconfont_airlyle icon-xinshouyindao"></i>
          </div>
        </div>
      </el-tooltip>
      <el-dialog title="Guide"
                 append-to-body
                 @close="close"
                 width="600px"
                 v-model="dialogShow">
        <template #header>
          <div class="title-box">
            <h2>Beginner's guide Video</h2>
          </div>
        </template>
        <div class="video-box">
<!--          <video id="guide-video" ref="video" src="/img/guide/guide.mp4" width="550" controls autoplay>-->
<!--          <iframe width="560" height="315" src="https://www.youtube.com/embed/zV3Wlghemy8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
            <iframe width="560" height="315" src="https://www.youtube.com/embed/mGcak8A0yDw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "guide-video",
  data(){
    return {
      dialogShow:false
    }
  },
  methods:{
    guideClick(){
      this.dialogShow = true;
    },
    close(){
      // document.getElementById('guide-video').currentTime = 0;
      this.dialogShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.fixed-footer{
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #000c17;
  z-index: 9999;
  >div{
    width: 40px;
    height: 40px;
    color: #000c17;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.video-box{
  video{
    margin: 0 auto;
  }
}
</style>
