export default {
  title: 'OMS系统',
  logoutTip: '退出系统, 是否继续?',
  submitText: '确定',
  cancelText: '取消',
  search: '请输入搜索内容',
  menuTip: '没有发现菜单',
  common: {
    condition: '条件',
    display: '显示',
    hide: '隐藏'
  },
  tip: {
    select: '请选择',
    input: '请输入'
  },
  upload: {
    upload: '点击上传',
    tip: '将文件拖到此处，或',
    select:'选取文件',
    file:'文件',
    download:'附件下载',
  },
  date: {
    start: '开始日期',
    end: '结束日期',
    t: '今日',
    y: '昨日',
    n: '近7天',
    a: '全部'
  },
  form: {
    printBtn: '打 印',
    mockBtn: '模 拟',
    submitBtn: '提 交',
    emptyBtn: '清 空'
  },
  crud: {
    filter: {
      addBtn: '新增条件',
      clearBtn: '清空数据',
      resetBtn: '清空条件',
      cancelBtn: '取 消',
      submitBtn: '确 定'
    },
    column: {
      name: '列名',
      hide: '隐藏',
      fixed: '冻结',
      filters: '过滤',
      sortable: '排序',
      index: '顺序',
      width: '宽度'
    },
    tipStartTitle: '当前表格已选择',
    tipEndTitle: '项',
    editTitle: '编 辑',
    copyTitle: '复 制',
    addTitle: '新 增',
    viewTitle: '查 看',
    filterTitle: '过滤条件',
    showTitle: '列显隐',
    tableItem: '序号#',
    menu: '操作',
    addBtn: '新 增',
    show: '显 示',
    hide: '隐 藏',
    open: '展 开',
    shrink: '收 缩',
    printBtn: '打 印',
    excelBtn: '导 出',
    updateBtn: '修 改',
    cancelBtn: '取 消',
    searchBtn: '搜 索',
    emptyBtn: '清 空',
    menuBtn: '功 能',
    saveBtn: '保 存',
    viewBtn: '查 看',
    editBtn: '编 辑',
    copyBtn: '复 制',
    delBtn: '删 除',
    system: '系统管理'
  },
  notify: {
    title: '通知',
    termsNotify: '您需要同意我们的条款和隐私',
    error: '网络异常，请重试',
  },
  login: {
    title: '登录',
    info: '欢迎回来',
    emailLabel: '邮箱地址',
    passwordLabel: '密码',
    confirmPasswordLabel: '确认密码',
    tenantId: '请输入租户ID',
    email: '请输入邮箱地址',
    username: '请输入账号',
    password: '请输入密码',
    confirmPassword: '再次确认密码',
    rulePassword: '密码长度最少为6位',
    wechat: '微信',
    qq: 'QQ',
    github: 'github',
    gitee: '码云',
    phone: '请输入手机号',
    code: '请输入验证码',
    submit: '登录',
    userLogin: '账号密码登录',
    phoneLogin: '手机号登录',
    thirdLogin: '第三方系统登录',
    ssoLogin: '单点系统登录',
    msgText: '发送验证码',
    msgSuccess: '秒后重发',
    goForget: '忘记密码?',
    registerTip: '已有账户?',
    loginTip: '还没有账户?',
    goRegister: '立即开始',
    goLogin: '现在登录',
    load: '登录中,请稍后....'
  },
  register: {
    firstName: '姓',
    firstNameError: '请输入您的姓',
    lastName: '名',
    lastNameError: '请输入您的名',
    ordersError: '此项不能为空',
    passwordAgainError: '密码不一致',
    shippingMonthLabel: '您每月大概买多少次？',
    submit: '立即注册',
    load: '正在注册，请稍候......'
  },
  forget: {
    title: '忘记密码?',
    resetTitle: '重置密码',
    submit: '发送重置链接',
    codeLabel: '验证码',
  },
  reset: {
    load: '重置中,请稍后....'
  },
  navbar: {
    logOut: '退出登录',
    userinfo: '个人信息',
    switchDept: '部门切换',
    dashboard: '首页',
    lock: '锁屏',
    bug: '没有错误日志',
    bugs: '条错误日志',
    screenfullF: '退出全屏',
    screenfull: '全屏',
    language: '中英文',
    notice: '消息通知',
    theme: '主题',
    color: '换色'
  },
  tagsView: {
    search: '搜索',
    menu: '更多',
    clearCache: '清除缓存',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  dataSearch: {
    menuName: '菜单名称',//菜单名称
    menuNumber: '菜单编号',//菜单编号
    menuAlias: '菜单别名',//菜单别名
    addChild: '新增子项',//新增子项
    delete: '删除',//删除
    add: '新增',//新增
  },
  formRule: {
    url: '请输入正确的url链接'
  },
  store: {
    source: '来源',
    address: '地址',
    status: '状态',
    createtime: '创建日期',
    operator: '操作员',
  },
  product: {
    source: '来源',
    category: '类型',
    product: '商品',
  },
  operate: {
    delete: '删除',//删除
    add: '新增',//新增
    view: '查看',//查看
    editor: '编辑',//编辑
    next: '下一步',//下一步
    cancel: '取消',//取消
    confirm: '确定',//确定
    back: '上一步',//上一步
    save: '保存',//保存
    download: '下载',//下载
    import: '导入',//导入
    export: '导出',//导出
    update_sku_stock: '更新库存',//更新库存
    createOrder: '创建工单',//
    process: '处理',//
    reply: '追加问题',//
    nodata: '暂无数据',//
    reset: '重置',
    filter: '筛选',
    create: '创建',
    upload: '上传',
  },
  list: {
    allStore: '所有店铺',
    allDestinations: '所有目的地',
    remark: '备注',
    ec: 'E仓',
    chooseCarriers: '选择物流',
    confirmShipment: '订单审核',
    orderInformation: '订单信息',
  },
  sku_inventory: {
    ShopifySKU: '平台sku',
    WarehouseSKU: '仓库sku',
    ProductName: '产品名称',
    StoreName: '店铺名称',
    WarehouseCode: '仓库代码',
    InboundReceiving: '在途数量',
    PutAway: '待上架数量',
    AvailableforSale: '可售数量',
    PiCanSaleDays: '可售天数',
    Defective: '不合格数量',
    AwaitingOutbound: '待出库数量',
    Shipped: '历史出库数量',
    Notes: '仓库描述',
  },
  task_list: {
    tab: {
      all: '全部',
      awaiting: '待处理',
      processing: '处理中',
      closed: '已完成',
    },
    btn: {
      reopen: '重开',
      reply: '回复',
      refuse: '拒绝',
      complete: '已完成',
    },
    taskCode: '任务编号',
    createUserName: '业务员',
    merchantCode: '客户代码',
    merchantName: '客户名称',
    typeName: '任务类型',
    priorityName: '紧急程度',
    statusName: '仓库处理状态',
    estimatedCompletionTime: '预计处理完成时间',
    completeTime: '实际完成时间',
    age: '时效',
    createTime: '提交时间',
    item: '商品',
    freezeCartons: '纸箱冻结',
    detail: {
      tab_view: '任务详情',
      tab_time: '修改时间记录',
      tag: '标签',
      description: '任务描述',
      setCompletionTime: '将预计处理完成时间设置为',
      reasonForModificationTime: '修改时间原因',
      warehouseReply:'仓库回复',
      myReply:'我的回复',
      businessReply:'业务回复',
    }
  },
  // 海箱追踪模块
  track:{
    createShipment:{
      MBLBookingNumber:'请输入MBL/预订编号'
    },
    list:{
      download_error:'暂无文件可下载'
    }
  },
  order:{
    bill:{
      verify:'核对',
      pleaseVerified:'是否已经核对账单？',
      receivedPayment:'销账',
      pleaseProofs:'请上传销账凭证',
      ReGeneratetheInvoice:'重新生成',
      pleaseReGeneratetheInvoice:'是否重新生成账单？',
      checkPaymentProofs:'查看销账凭证',
      NoProofs:'暂无销账凭证',
    }
  }
}
