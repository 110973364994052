
// 报价-选择物流线路 自定义新增名称和线路后保存
import {getStore, setStore} from '@/utils/store.js'
import {validatenull} from '@/utils/validate.js'
import {deepClone} from '@/utils/util.js'

let locationName = 'logisticsNameAndLine';
const quotationLogistics = {
  state: {
    logisticsAutoNameAndLine: getStore({name: locationName}) || {}
  },
  actions: {
    // 自定义新增名称和线路后保存
    add_quotation_logistics_auto_name_and_line({commit},data){
      return  new Promise((resolve,reject)=>{
        commit('ADD_QUOTATION_LOGISTICS_AUTO_NAME_AND_LINE',data)
      })
    },
  },
  mutations: {
    ADD_QUOTATION_LOGISTICS_AUTO_NAME_AND_LINE: (state, data) => {
      let {name,line,nameList} = data;
      let nameAndLine = state.logisticsAutoNameAndLine;
      let list = nameList.map(item=>item.value)
      let json = deepClone(nameAndLine);
      // 不在默认线路 则保存下来
      if(!list.includes(name)){
        console.log(json)
        // 判断是否为空 如果为空 则直接保存
        console.log(JSON.stringify(json))
        console.log('判断是否为空 如果为空 则直接保存',validatenull(json))
        if(validatenull(json)){
          json[name] = [line];
        }
        if(validatenull(json[name])){
          console.log('判断名称是否存在 如果不存在 则直接保存',validatenull(json[name]))
          // 判断名称是否存在 如果不存在 则直接保存
          json[name] = [line];
        }else if(!json[name].includes(line)){
          console.log('如果名称存在 则判断线路是否一致 不一致则push',!json[name].includes(line))
          json[name].push(line)
        }


        // 如果名称存在 则判断线路是否一致


        setStore({name:locationName,content:json})
        state.logisticsAutoNameAndLine = json;
      }
    },
  }

};

export default quotationLogistics;
