<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div class="avue-breadcrumb"
           :class="[{ 'avue-breadcrumb--active': isCollapse }]"
           v-if="showCollapse">
        <!--                <i class="icon-navicon" @click="setCollapse"></i>-->
        <div @click="setCollapse">
          <svg-icon icon-class="menu-menu_icon" class-name="menu_icon"/>
        </div>
      </div>
    </div>
    <div class="top-bar__title">
      <!--            <tags/>-->
      <breadcrumb/>
    </div>
    <div class="top-bar__right">
      <!--            <div class="top-bar__item">-->
      <!--                <div class="help-item" @click="helpClick">-->
      <!--                    <icon class="icon icon-help"></icon>-->
      <!--                </div>-->
      <!--            </div>-->
<!--                  <div class="top-bar__item">-->
<!--                      <el-tooltip class="item" effect="dark" content="Guide" placement="bottom">-->
<!--                          <div @click="guideClick">-->
<!--                              <icon class="icon-xinshouyindao white"></icon>-->
<!--                          </div>-->
<!--                      </el-tooltip>-->
<!--                  </div>-->
      <!--   中英文切换 暂时取消   -->
      <!--      <el-tooltip effect="dark"-->
      <!--                  :content="$t('navbar.language')"-->
      <!--                  placement="bottom">-->
      <!--        <div class="top-bar__item top-bar__item&#45;&#45;show">-->
      <!--          <top-lang></top-lang>-->
      <!--        </div>-->
      <!--      </el-tooltip>-->
      <el-tooltip v-if="showFullScren"
                  effect="dark"
                  :content="isFullScren?$t('navbar.screenfullF'):$t('navbar.screenfull')"
                  placement="bottom">
        <div class="top-bar__item">
          <i :class="isFullScren?'icon-tuichuquanping':'icon-quanping'"
             @click="handleScreen"></i>
        </div>
      </el-tooltip>
      <!--            通告-->
      <!--            <el-tooltip effect="dark"-->
      <!--                        :content="$t('navbar.notice')"-->
      <!--                        placement="bottom">-->
      <!--                    <div class="top-bar__item top-bar__item&#45;&#45;show">-->
      <!--                        <top-notice />-->
      <!--                    </div>-->
      <!--            </el-tooltip>-->
      <div class="top-bar__item top-bar__item--show">
        <top-notice/>
      </div>

      <el-dropdown>
          <span class="el-dropdown-link layout_userinfo_drop">
              <svg-icon icon-class="layout-layout_userinfo" class-name="layout_userinfo fs-24"/>
          </span>
        <template #dropdown>
          <el-dropdown-menu class="layout_userinfo_dropdown">
            <el-dropdown-item>
              <router-link to="/info/index" style="line-height: 46px;display: block">{{ userInfo.user_name }}</router-link>
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout"
                              divided>{{ $t('navbar.logOut') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>


  </div>
</template>
<script>
import {resetRouter} from '@/router'
import {mapGetters, mapState} from "vuex";
import {fullscreenToggel, listenfullscreen} from "@/utils/util";
import topLock from "./top-lock.vue";
import topMenu from "./top-menu.vue";
import topSearch from "./top-search.vue";
import topTheme from "./top-theme.vue";
import topLogs from "./top-logs.vue";
import topNotice from './top-notice.vue'
import topLang from "./top-lang.vue";
import Tags from "../tags.vue";
import breadcrumb from "../breadcrumb/index.vue";

export default {
  components: {
    Tags,
    topLock,
    topMenu,
    topSearch,
    topTheme,
    topLogs,
    topNotice,
    topLang,
    breadcrumb,
  },
  name: "top",
  data() {
    return {
      greeting_text: ''
    };
  },
  created() {
  },
  mounted() {
    listenfullscreen(this.setScreen);
    this.greeting();
  },
  computed: {
    ...mapState({
      showDebug: state => state.common.showDebug,
      showTheme: state => state.common.showTheme,
      showLock: state => state.common.showLock,
      showFullScren: state => state.common.showFullScren,
      showCollapse: state => state.common.showCollapse,
      showSearch: state => state.common.showSearch,
      showMenu: state => state.common.showMenu,
      showColor: state => state.common.showColor
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag"
    ])
  },
  methods: {
    greeting() {
      let hour = new Date().getHours();
      if ( hour < 12 ) {
        this.greeting_text = 'Good morning！'
      } else if ( hour < 17 ) {
        this.greeting_text = "Good afternoon！"
      } else {
        this.greeting_text = "Good evening!"
      }
    },
    handleScreen() {
      fullscreenToggel();
    },
    setCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    setScreen() {
      this.$store.commit("SET_FULLSCREN");
    },
    logout() {
      this.$confirm(this.$t("logoutTip"), "Tip", {
        confirmButtonText: this.$t("submitText"),
        cancelButtonText: this.$t("cancelText"),
        type: "warning"
      }).then(() => {
          // resetRouter();
        this.$store.dispatch("LogOut").then(() => {
            resetRouter();
          this.$router.push({ path: "/login" });
        });
      });
    },
    // guide 新手指南
    guideClick() {
      this.$store.dispatch('set_guide_show', true)
    },
    // 用户中心
    helpClick(){
      this.$router.push({
        path: '/help'
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.icon-xinshouyindao {
  cursor: pointer;
}
.help-item{
  height: 100%;
  display: flex;
  align-items: center;
}
.icon-help{
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("/img/help/icon_help.png") no-repeat center;
  -webkit-background-size: 100%;
  background-size: 100%;
  vertical-align: top;
}

.layout_userinfo_drop {
  display: flex;

  .layout_userinfo {
    font-size: 20px;
    margin-left: 10px;
  }
}

.layout_userinfo_dropdown {
  width: 187px;
  padding: 5px 0;
  //margin: 0;
  :deep(.el-dropdown-menu__item){
    padding: 3px 16px;
  }
  :deep(.el-dropdown-menu__item--divided){
    margin-top: 0;
  }
}
</style>
