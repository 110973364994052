import Layout from '@/page/index/index.vue'
import LayoutComponent from '@/page/index/layout.vue'

export default [{
  path: '/wel',
  component: Layout,
  redirect: '/wel/index',
  children: [{
    path: 'index',
    name: 'Home',
    meta: {
      i18n: 'dashboard'
    },
    component: () =>
      import( /* webpackChunkName: "home" */ '@/views/wel/index.vue')
  }, {
    path: 'dashboard',
    name: '控制台',
    meta: {
      i18n: 'dashboard',
      menu: false,
    },
    component: () =>
      import( /* webpackChunkName: "home" */ '@/views/wel/dashboard.vue')
  }, {
    path: 'new_dashboard',
    name: 'Dashboard',
    meta: {
      i18n: 'dashboard',
      // menu: false,
    },
    component: () =>
      import( /* webpackChunkName: "home" */ '@/views/wel/new_dashboard.vue')
  }]
},
  // 新手引导
  // {
  //   path: '/guide',
  //   component: Layout,
  //   redirect: '/guide',
  //   children: [{
  //     path: '',
  //     name: 'guide',
  //     meta: {
  //       i18n: 'guide'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "guide" */ '@/views/guide/index.vue')
  //   }]
  // },
  // {
  //   path: '/test',
  //   component: Layout,
  //   redirect: '/test/index',
  //   children: [{
  //     path: 'index',
  //     name: '测试页',
  //     meta: {
  //       i18n: 'test'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "views" */ '@/views/util/test.vue')
  //   }]
  // },
  // {
  //   path: '/test',
  //   component: Layout,
  //   redirect: '/test/index',
  //   children: [{
  //     path: 'index',
  //     name: '测试页',
  //     meta: {
  //       i18n: 'test'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "views" */ '@/views/demo/demo.vue')
  //   }]
  // },
  {
    path: '/dict-horizontal',
    component: Layout,
    redirect: '/dict-horizontal/index',
    children: [{
      path: 'index',
      name: '字典管理',
      meta: {
        i18n: 'dict'
      },
      component: () =>
        import( /* webpackChunkName: "dict" */ '@/views/util/demo/dict-horizontal.vue')
    }]
  },
  {
    path: '/dict-vertical',
    component: Layout,
    redirect: '/dict-vertical/index',
    children: [{
      path: 'index',
      name: '字典管理',
      meta: {
        i18n: 'dict'
      },
      component: () =>
        import( /* webpackChunkName: "dict" */ '@/views/util/demo/dict-vertical.vue')
    }]
  },
  {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    children: [{
      path: 'index',
      name: '个人信息',
      meta: {
        i18n: 'info'
      },
      component: () =>
        import( /* webpackChunkName: "info" */ '@/views/system/userinfo.vue')
    }]
  },
  {
    path: '/work/process/leave',
    component: Layout,
    redirect: '/work/process/leave/form',
    children: [
      {
        path: 'form/:processDefinitionId',
        name: '请假流程',
        meta: {
          i18n: 'work'
        },
        component: () =>
          import( /* webpackChunkName: "work" */ '@/views/work/process/leave/form.vue')
      }, {
        path: 'handle/:taskId/:processInstanceId/:businessId',
        name: '处理请假流程',
        meta: {
          i18n: 'work'
        },
        component: () =>
          import( /* webpackChunkName: "work" */ '@/views/work/process/leave/handle.vue')
      }, {
        path: 'detail/:processInstanceId/:businessId',
        name: '请假流程详情',
        meta: {
          i18n: 'work'
        },
        component: () =>
          import( /* webpackChunkName: "work" */ '@/views/work/process/leave/detail.vue')
      }]
  },
  // {
  //   path: '/demo',
  //   component: Layout,
  //   redirect: '/demo/index',
  //   children: [{
  //     path: 'index',
  //     name: '测试页',
  //     meta: {
  //       i18n: 'test'
  //     },
  //     component: () =>
  //       import( /* webpackChunkName: "views" */ '@/views/demo/demo.vue')
  //   }]
  // },
  {
    path: '/store/relate',
    component: Layout,
    redirect: '/store/relate',
    children: [{
      path: '',
      name: '绑定店铺',
      meta: {
        i18n: 'store'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/store/relate.vue')
    }]
  },
  {
    path: '/product/detail/:id',
    component: Layout,
    children: [{
      path: '',
      name: 'Product Detail',
      meta: {
        i18n: 'product_detail'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/product/detail.vue')
    }]
  },
  {
    path: '/oms_product',
    component: Layout,
    name: 'Products list',
    redirect: '/oms_product/list',
    children: [
      {
        path: 'add',
        name: 'Add product',
        meta: {
          i18n: 'product_add'
        },
        component: () =>
          import( /* webpackChunkName: "oms_product-views" */ '@/views/oms_product/add_product.vue')
      },
      {
        path: 'detail/:id',
        name: 'Product detail',
        meta: {
          i18n: 'product_detail'
        },
        component: () =>
          import( /* webpackChunkName: "oms_product-views" */ '@/views/oms_product/add_product.vue')
      },
    ]
  },
  {
    path: '/workorder',
    component: Layout,
    name: 'Work order',
    redirect: '/workorder/list',
    children: [{
      path: 'reply/:id',
      name: 'Reply',
      meta: {
        i18n: 'store'
      },
      component: () =>
        import( /* webpackChunkName: "workorder-views" */ '@/views/workorder/component/workorder.vue')
    }]
  },
  {
    path: '/return_label',
    component: Layout,
    // name重复会导致路由找不到
    name: 'RETURN_LABEL_STATIC',
    meta: {
      // title: '退货追踪列表'
      title: 'Return tracking list'
    },
    children: [
      {
        path: 'fnsku/:trackCode',
        name: 'RETURN_LABEL_FNSKU_LIST_STATIC',
        meta: {
          title: 'Fnsku list'
        },
        component: () =>
          import( /* webpackChunkName: "rebrand-views" */ '@/views/return_label/fnsku_code.vue')
      }
    ]
  },
  {
    path: '/rebrand',
    component: Layout,
    name: '换标',
    children: [
      {
        path: 'to_be_boxed/detail',
        name: '装箱',
        component: () =>
          import( /* webpackChunkName: "rebrand-views" */ '@/views/rebrand/box.vue')
      },
      {
        path: 'fnsku_batch/detail/:id',
        name: 'FBA标详情',
        component: () =>
          import( /* webpackChunkName: "rebrand-views" */ '@/views/rebrand/fba/detail.vue')
      }
    ]
  },
  {
    path: '/package',
    component: Layout,
    name: '包裹列表',
    redirect: '/package/list',
    children: [
      {
        path: 'detail/:id',
        name: '包裹详情',
        component: () =>
          import( /* webpackChunkName: "package-views" */ '@/views/package/detail.vue')
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    name: 'Order',
    // redirect: '/order/create_order',
    children: [
      {
        path: 'create',
        name: 'Create Order',
        component: () =>
          import( /* webpackChunkName: "order-views" */ '@/views/order/createOrder/createOrder.vue')
      },
      //20240821 新order创建按钮跳转页面
      {
        path: 'new_create_order',
        name: 'new_create_order',
        meta: {
          title:'',
          // keepAlive: true,
          activeMenu:'/order/ecommerce_orders'
        },
        component: () =>
          import( /* webpackChunkName: "order-views" */ '@/views/order/createOrder/new_create_order.vue')
      },
      //20240822 新order详情页面
      {
        path: 'new_order_detail',
        name: 'new_order_detail',
        meta: {
          title:'Order Detail',
          // keepAlive: true,
          activeMenu:'/order/ecommerce_orders'
        },
        component: () =>
          import( /* webpackChunkName: "order-views" */ '@/views/order/new_order_detail.vue')
      },
    ]
  },
  {
    path: '/track',
    component: Layout,
    name: 'track_and_trace',
    redirect: '/track/sku_Inbound',
    meta: {
      title:'TRACK & TRACE',
    },
    children: [
      {
        path: '/track/sku_inbound/create',
        name: 'track_sku_inbound_create',
        meta: {
          title:'Create SKU Warehousing Audit',
          activeMenu:'/track/sku_Inbound'
        },
        component: () =>
          import( /* webpackChunkName: "sku_inbound" */ '@/views/track/sku_inbound/detail.vue')
      },
      {
        path: '/track/sku_inbound/:id',
        name: 'track_sku_inbound_detail',
        meta: {
          title:'SKU warehousing audit',
          activeMenu:'/track/sku_Inbound'
        },
        component: () =>
          import( /* webpackChunkName: "sku_inbound" */ '@/views/track/sku_inbound/detail.vue')
      },
      {
        path: '/track/inventory_review/:id',
        name: 'track_sku_inventory_review_detail',
        component: () =>
          import( /* webpackChunkName: "sku_inbound" */ '@/views/track/sku_inbound/review_detail.vue')
      }
    ]
  },
  {
    path: '/airOMS',
    component: Layout,
    name: 'airOMS',
    redirect: '/airOMS/quotation/list',
    children: [
      // 20240130最新版 start
      {
        path: '/airOMS/quotation/result/:taskNum',
        name: 'quotation-result-view',
        meta: {
          title:'Quotation Result View',
          cn_title:"报价结果",
          keepAlive: true,
          // activeMenu:'/airOMS/quotation/list'
        },
        component: () =>
          import( /* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/result/list.vue')
      },
      {
        path: '/airOMS/quotation/task/:taskId',
        name: 'quotation-task-detail',
        meta: {
          title:'Quotation Task',
          keepAlive:true,
          activeMenu:'/airOMS/quotation/task_list'
        },
        component: () =>
          import( /* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/task_detail.vue')
      },
      {
        path: '/airOMS/quotation/inquiry/:taskId',
        name: "quotation-task-create-inquiry",
        meta: {
          title:'Quotation Inquiry',
          keepAlive:true,
          activeMenu:'/airOMS/quotation/task_list'
        },
        component: () =>
          import(/* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/inquiry/index.vue')
      },
      {
        // 产品采购信息追加供应商
        path: '/airOMS/quotation/product/add/:productCode',
        name: "quotation-task-product-add-to-supplier",
        meta: {
          title:'Quotation Product Information',
          keepAlive:false,
          activeMenu:'/airOMS/quotation/product/list'
        },
        component: () =>
          import(/* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/product/product-info.vue')
      },
      {
        // 产品采购信息追加变种
        path: '/airOMS/quotation/product/add-variantinfo/:productCode',
        name: "quotation-task-product-add-variantinfo",
        meta: {
          title:'Quotation Product Information',
          keepAlive:false,
          activeMenu:'/airOMS/quotation/product/list'
        },
        component: () =>
          import(/* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/product/add-variantinfo.vue')
      },
      {
        // 预览
        path: '/airOMS/quotation/task/preview/:taskNum',
        name: 'Preview Quotation Result',
        meta: {
          title:'Preview',
          activeMenu:'/airOMS/quotation/task_list'
        },
        component: () =>
          import( /* webpackChunkName: "quotation" */ '@/views/airOMS/quotation/result/preview.vue')
      },
      // end
      // {
      //   path: '/airOMS/quotation/detail/:id',
      //   name: 'Quotation-Task-View',
      //   meta: {
      //     title:'Quotation Task View',
      //     keepAlive:false,
      //     activeMenu:'/airOMS/quotation/list'
      //   },
      //   component: () =>
      //     import( /* webpackChunkName: "quotation-process" */ '@/views/airOMS/_quotation/task/detail.vue')
      // },
      // // 报价产品详情
      // {
      //   path: '/airOMS/quotation/task_detail/:id',
      //   name: 'Quotation Task Product View',
      //   meta: {
      //     title:'Quotation Task Product View',
      //     activeMenu:'/airOMS/quotation/list'
      //   },
      //   component: () =>
      //     import( /* webpackChunkName: "quotation-process" */ '@/views/airOMS/_quotation/task/task_detail.vue')
      // },
      // // 添加报价信息
      // {
      //   path: '/airOMS/quotation/task_detail/create_quotation/:id',
      //   name: 'Add Quotation Info',
      //   meta: {
      //     title:'Add Quotation Info',
      //     activeMenu:'/airOMS/quotation/list'
      //   },
      //   component: () =>
      //     import( /* webpackChunkName: "quotation-process" */ '@/views/airOMS/_quotation/task/quote/quotation-info-page.vue')
      // },
      // // 产品描述
      // {
      //   path: '/airOMS/quotation/task_detail/product_describe/:id',
      //   name: 'Quotation Product Describe',
      //   meta: {
      //     title:'Product Describe',
      //     activeMenu:'/airOMS/quotation/list'
      //   },
      //   component: () =>
      //     import( /* webpackChunkName: "quotation-describe" */ '@/views/airOMS/_quotation/task/product/describe.vue')
      // },
      // // 产品描述
      // {
      //   path: '/airOMS/quotation/task_detail/preview/:id/:groupId',
      //   name: 'Preview Quotation Result',
      //   meta: {
      //     title:'Preview',
      //     activeMenu:'/airOMS/quotation/list'
      //   },
      //   component: () =>
      //     import( /* webpackChunkName: "quotation-preview" */ '@/views/airOMS/_quotation/task/result/preview.vue')
      // },


      {
        path: '/airOMS/warehouse/goods_warehouse/:id',
        name: '仓库管理-商品入库',
        meta: {
          title:'商品入库',
          activeMenu:'/airOMS/warehouse/list'
        },
        component: () =>
          import( /* webpackChunkName: "warehouse" */ '@/views/warehouse/goods_warehouse.vue')
      },
      // 新版仓库入库出库页面
      {
        path: '/airOMS/warehouse/re_pallet/:id',
        name: 'shipping-plans-re-pallet',
        meta: {
          title:'Inbound',
          activeMenu:'/track/shipping_plans'
        },
        component: () =>
          import( /* webpackChunkName: "warehouse" */ '@/views/airOMS/warehouse/re_pallet.vue')
      },

      //20240521 新增Outbound页面
      {
        path: '/airOMS/warehouse/re_outbound',
        name: 'shipping-plans-re_outbound',
        meta: {
          title:'Outbound',
          activeMenu:'/warehouse/merchandise_inventory'
        },
        component: () =>
          import( /* webpackChunkName: "warehouse" */ '@/views/airOMS/warehouse/re_outbound.vue')
      },
      {
        path: 'warehouse',
        name: 'airOMS-库存管理',
        meta: {
          title:'Inventory Management',
        },
        redirect: '/inventory/po_client_order',
        component: LayoutComponent,
        children:[
          {
            path: '/airOMS/inventory/deliver_goods_bill/:id',
            name: '库存管理-Po采购订单-发货单',
            meta: {
              title:'Po Purchase Order',
              activeMenu:'/warehouse/po_client_order'
            },
            component: () =>
              import( /* webpackChunkName: "warehouse-po" */ '@/views/warehouse/pages/deliver_goods_bill.vue')
          }
        ]
      },
      //20240529 新增inventory_replenishment创建按钮跳转页面
      {
        path: '/warehouse/inventory_replenishment_create',
        name: 'inventory_replenishment_create_btn',
        meta: {
          title:'Create',
          activeMenu:'/warehouse/inventory_replenishment'
        },
        component: () =>
          import( /* webpackChunkName: "warehouse" */ '@/views/airOMS/warehouse/inventory_replenishment_create.vue')
      },
    ]
  },
  // {
  //     path: '/airOMS',
  //     component: Layout,
  //     name: 'airOMS1',
  //     meta: {
  //         title:'airOMS',
  //     },
  //     redirect: '/warehouse/po_client_order',
  //     children: [
  //         {
  //             path: 'warehouse',
  //             name: 'airOMS-库存管理',
  //             meta: {
  //                 title:'Inventory Management',
  //             },
  //             redirect: '/warehouse/po_client_order',
  //             component: LayoutComponent,
  //             children:[
  //                 {
  //                     path: '/airOMS/warehouse/deliver_goods_bill/:id',
  //                     name: '库存管理-Po采购订单-发货单',
  //                     meta: {
  //                         title:'Po Purchase Order',
  //                         activeMenu:'/warehouse/po_client_order'
  //                     },
  //                     component: () =>
  //                         import( /* webpackChunkName: "warehouse-po" */ '@/views/warehouse/pages/deliver_goods_bill.vue')
  //                 }
  //             ]
  //         },
  //     ]
  // },
  // {
  //   path: '*',
  //   redirect: '/404'
  // },

]
