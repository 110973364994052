import { setStore, getStore } from '@/utils/store';
import {diff} from '@/utils/util'
import website from '@/config/website';

const isFirstPage = website.isFirstPage;
const tagWel = website.fistPage;
const tagObj = {
  label: '', //标题名称
  value: '', //标题的路径
  params: '', //标题的路径参数
  query: '', //标题的参数
  meta: {},//额外参数
  group: [], //分组
}

//处理首个标签
function setFistTag(list) {
  if (list.length === 1) {
    list[0].close = false;
  } else {
    list.forEach(ele => {
      if (ele.value === tagWel.value && isFirstPage === false) {
        ele.close = false
      } else {
        ele.close = true
      }
    })
  }
}


const navs = {
  state: {
    tagList: getStore({ name: 'tagList' }) || [],
    tag: getStore({ name: 'tag' }) || {},
    tagWel: tagWel,
  },
  mutations: {
    ADD_TAG: (state, action) => {
      // console.log('ADD_TAG-1-',action)
      if (typeof action.name == 'function') action.name = action.name(action.query);
      state.tag = action;
      setStore({name: 'tag', content: state.tag})
      // console.log('action',action);
      // console.log('tagList',state.tagList);
      if (state.tagList.some(ele =>{
        let bOk = diff(ele, action);
        // 解决动态路由 diff判断失败问题
        if(!bOk){
          return ele.value === action.value
        }else{
          return bOk
        }
      })) return
      state.tagList.push(action)
      setFistTag(state.tagList);
      setStore({name: 'tagList', content: state.tagList})
    },
    // ADD_TAG: (state, action) => {
    //   if (typeof action.name == 'function') action.name = action.name(action.query);
    //   state.tag = action;
    //   setStore({ name: 'tag', content: state.tag });
    //   if (state.tagList.some(ele => ele.fullPath == action.fullPath)) return;
    //   state.tagList.push(action);
    //   setStore({ name: 'tagList', content: state.tagList });
    // },
    DEL_TAG: (state, action) => {
      state.tagList = state.tagList.filter(item => {
        return !diff(item, action);
      })
      setFistTag(state.tagList);
      setStore({name: 'tagList', content: state.tagList})
    },
    DEL_ALL_TAG: (state, tagList = []) => {
      state.tagList = [state.tagWel,...tagList];
      setStore({ name: 'tagList', content: state.tagList });
    },
    // DEL_TAG_OTHER: state => {
    //   state.tagList = state.tagList.filter(item => {
    //     return [state.tag.fullPath, website.fistPage.path].includes(item.fullPath);
    //   });
    //   setStore({ name: 'tagList', content: state.tagList });
    // },
    DEL_TAG_OTHER: (state) => {
      state.tagList = state.tagList.filter(item => {
        if (item.value === state.tag.value) {
          return true;
        } else if (!website.isFirstPage && item.value === website.fistPage.value) {
          return true;
        }
      })
      setFistTag(state.tagList);
      setStore({name: 'tagList', content: state.tagList})
    },
    // 删除当前路由tag
    DEL_TAG_ONE: (state,action) => {
      state.tagList = state.tagList.filter(item => {
        if (item.value !== action) {
          return true;
        }
      })
      setFistTag(state.tagList);
      setStore({name: 'tagList', content: state.tagList})
    },
    SET_TAG_LIST(state, tagList) {
      state.tagList = tagList;
      setStore({name: 'tagList', content: state.tagList})
    }
  },
};
export default navs;
