import {getStore, setStore} from '@/utils/store'

const omsProduct = {
  state: {
    brandAll: getStore({name: 'brandAll'}) || {},
    attrAll: getStore({name: 'attrAll'}) || {},
  },
  actions: {
    set_brand_all({commit},data) {
      commit('GET_BRAND_ALL', data);
    },
    set_attr_all({commit},data) {
      commit('GET_ATTR_ALL', data);
    },
  },
  mutations: {
    GET_BRAND_ALL: (state, data) => {
      state.brandAll = data;
      setStore({name: 'brandAll', content: state.brandAll})
    },
    GET_ATTR_ALL: (state, data) => {
      state.attrAll = data;
      setStore({name: 'attrAll', content: state.attrAll})
    },
  }

};

export default omsProduct;
