import {getStore, setStore} from '@/utils/store';

const tableColumn = {
  state: {
    // tableColumn: getStore({ name: 'tableColumn' }) || {},
    // filtersDefault: getStore({ name: 'filtersDefault' }) || {},
    tableColumn: {},
    filtersDefault: {},
  },
  actions: {
    setTableColumn({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_TABLE_COLUMN', data);
        resolve();
      })
    },
    setFiltersDefault({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_FILTERS_DEFAULT', data);
        resolve(data.value);
      })
    },
  },
  mutations: {
    SET_TABLE_COLUMN: (state, data) => {
      state.tableColumn[data.name] = data.value
      setStore({ name: 'tableColumn', type: 'session', content: state.tableColumn });
    },
    SET_FILTERS_DEFAULT: (state, data) => {
      state.filtersDefault[data.name] = data.value
      setStore({ name: 'filtersDefault', type: 'session', content: state.filtersDefault });
    },
  }

};

export default tableColumn;
