import Cookies from 'js-cookie'
import { setStore ,getStore } from "@/utils/store";
//新手引导
const guide = {
  state: {
    order: Cookies.get('airly_guide_order') || false,
    product: Cookies.get('airly_guide_product') || false,
    guideShow: getStore({ name:'guide_show'}) || false,
    guideIsShow: getStore({ name:'guide_is_show'}) || true,
  },
  actions: {
    set_guide_order_name({commit}) {
      return new Promise((resolve) => {
        commit('SET_GUIDE_ORDER_NAME');
        resolve();
      })
    },
    set_guide_product_name({commit}) {
      return new Promise((resolve) => {
        commit('SET_GUIDE_PRODUCT_NAME');
        resolve();
      })
    },
    set_guide_show({commit},data) {
      return new Promise((resolve) => {
        commit('SET_GUIDE_SHOW',data);
        commit('SET_GUIDE_IS_SHOW');
        resolve();
      })
    },
  },
  mutations: {
    SET_GUIDE_SHOW: (state,data) => {
      state.guideShow = data;
      console.log('SET_GUIDE_SHOW',state);
      setStore({ name:'guide_show',content:data});
    },
    SET_GUIDE_IS_SHOW: (state) => {
      state.guideIsShow = true;
      setStore({ name:'guide_is_show',content:true});
    },
    SET_GUIDE_ORDER_NAME: (state) => {
      state.order = true;
      Cookies.set('airly_guide_order',state.order,{ expires : 365 , path:''});
    },
    SET_GUIDE_PRODUCT_NAME: (state) => {
      state.product = true;
      Cookies.set('airly_guide_product',state.product,{ expires : 365 , path:''});
    },
  }

};

export default guide;
