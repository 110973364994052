import {getStore, setStore} from '@/utils/store';

const dict = {
    state: {
        workorder_title: getStore({name: 'airly_workorder_title'}) || '',
        workorders_statistics_store_list: [],
        workorders_statistics_store_week_list: [],
    },
    actions: {
        setWorkorderName({commit}, data) {
            return new Promise((resolve) => {
                commit('SET_WORKORDER_NAME', data);
                resolve();
            })
        },
        setWorkordersStatisticsStoreList({commit}, data) {
            return new Promise((resolve) => {
                commit('SET_WORKORDERS_STATISTICS_STORE_LIST', data);
                resolve();
            })
        },
        setWorkordersStatisticsStoreWeekList({commit}, data) {
            return new Promise((resolve) => {
                commit('SET_WORKORDERS_STATISTICS_STORE_WEEK_LIST', data);
                resolve();
            })
        },
    },
    mutations: {
        SET_WORKORDER_NAME: (state, data) => {
            state.workorder_title = data;
            setStore({name: 'airly_workorder_title', content: state.workorder_title});
        },
        SET_WORKORDERS_STATISTICS_STORE_LIST: (state, data) => {
            state.workorders_statistics_store_list = data;
        },
        SET_WORKORDERS_STATISTICS_STORE_WEEK_LIST: (state, data) => {
            state.workorders_statistics_store_week_list = data;
        },
    }

};

export default dict;
