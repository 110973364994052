import request from '@/axios';

export const getList = (current, size, params) => {
    return request({
        url: '/api/order/order/page',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}
// 20240822 新order页面 列表数据获取 （同接口，调试传参，与原页面调用区分）
export const getListV2 = (current, size, params) => {
    return request({
        url: '/api/order/order/page',
        method: 'get',
        params: {
            ...params,
            current,
            size,
        }
    })
}

export const getDetail = (id) => {
    return request({
        url: '/api/order/order/detail',
        method: 'get',
        params: {
            id
        }
    })
}

export const remove = (ids) => {
    return request({
        url: '/api/order/order/remove',
        method: 'post',
        params: {
            ids,
        }
    })
}

/**
 * 创建订单
 * @param data
 * @return {AxiosPromise}
 */
export const addOrder = (data) => {
    return request({
        url: '/api/order/order',
        method: 'post',
        data
    })
}

/**
 * 批量删除
 * @param data
 * @return {AxiosPromise}
 */
export const removeDel = (data) => {
    return request({
        url: '/api/order/order',
        method: 'delete',
        data
    })
}

export const add = (row) => {
    return request({
        url: '/api/order/order/submit',
        method: 'post',
        data: row
    })
}

export const update = (row) => {
    return request({
        url: '/api/order/order/submit',
        method: 'post',
        data: row
    })
}

/**
 * 美国所有的州
 * @returns {AxiosPromise}
 */
export const getAmericanProvince = () => {
    return request({
        url: '/api/order/order/american-province',
        method: 'get',
    })
}

/**
 * 获取订单地址
 * @param id
 * @return {AxiosPromise}
 */
export const getOrderAddressById = (id) => {
    return request({
        url: `/api/order/order/address/${ id }`,
        method: 'get',
    })
}

/**
 * 修改订单地址
 * @param data
 * @returns {AxiosPromise}
 */
export const putOrderAddress = (data) => {
    return request({
        url: `/api/order/order/address`,
        method: 'put',
        data
    })
}

/**
 * 修改订单项数量
 * @param data
 * @returns {AxiosPromise}
 */
export const putUpdateOrderItemQuantity = (data) => {
    return request({
        url: `/api/order/order/update-order-item`,
        // url: `/api/order/order/update-order-item-quantity`,
        method: 'put',
        data
    })
}

/**
 * 取消订单
 * @param id
 * @returns {AxiosPromise}
 */
export const putOrderCancelById = (id) => {
    return request({
        url: `/api/order/order/cancel/${ id }`,
        method: 'put',
    })
}

/**
 * 获取特殊物流下拉选项
 * @returns {AxiosPromise}
 */
export const getOrderSpecialDelivery = () => {
    return request({
        url: `/api/order/order/special-delivery`,
        method: 'get',
    })
}

/**
 * 获取物流公司信息
 * @returns {AxiosPromise}
 */
export const getOrderDeliveryInfos = () => {
    return request({
        url: `/api/order/order/delivery-infos`,
        method: 'get',
    })
}

/**
 * 获取发货仓列表
 * @returns {AxiosPromise}
 */
export const getOrderDeliveryInfoMap = () => {
    return request({
        url: `/api/order/order/delivery-info-map`,
        method: 'get',
    })
}

/**
 * 批量选择物流-选择发货仓等三级目录
 */
export const getWarehouseShippingMethodRela = (shippingMethod) => {
    return request({
        url: `/api/shipping-warehouse/warehouse-shipping-method-rela`,
        method: 'get',
        params: {
            shippingMethod
        }
    })
}

/**
 * 设置物流信息
 * @param data
 * {
 *   "infoList": [
 *     {
 *       "deliveryInfo": "",
 *       "orderId": 0
 *     }
 *   ],
 *   "submitType": 0 // 提交缓存区--11，提交待审核--12
 * }
 * @return {AxiosPromise}
 */
export const putOrderSetDeliveryInfo = (data) => {
    return request({
        url: `/api/order/order/set-delivery-info`,
        method: 'put',
        data
    })
}
/**
 * 设置发货仓库
 * @param orderIdList
 * @param warehouse
 * @param syncToPlatformType
 * @return {AxiosPromise}
 */
export const putOrderSetWarehouse = (orderIdList, warehouse, syncToPlatformType) => {
    return request({
        url: `/api/order/order/set-warehouse`,
        method: 'put',
        data: {
            orderIdList,
            warehouse,
            syncToPlatformType
        }
    })
}
/**
 * 设置物流信息(faire-order)
 * @param data
 * @return {AxiosPromise}
 */
export const putFaireOrderSetDeliveryInfo = (data) => {
    return request({
        url: `/api/faire-order/set-delivery-info`,
        method: 'put',
        data
    })
}

/**
 * 审核订单
 * @param data
 * {
 *   "orderIdList": []
 * }
 * @return {AxiosPromise}
 */
export const putOrderApproval = (data) => {
    return request({
        url: `/api/order/order/approval`,
        method: 'put',
        data
    })
}
/**
 * 审核订单
 * @param data
 * @return {AxiosPromise}
 */
export const putFaireOrderApproval = (data) => {
    return request({
        url: `/api/faire-order/approval`,
        method: 'put',
        data
    })
}

/**
 * 取消缓存
 * @param data
 * @return {AxiosPromise}
 */
export const putOrderRemoveStaging = (data) => {
    return request({
        url: `/api/order/order/remove-staging`,
        method: 'put',
        data
    })
}
/**
 * 取消缓存
 * @param data
 * @return {AxiosPromise}
 */
export const putFaireOrderRemoveStaging = (data) => {
    return request({
        url: `/api/faire-order/remove-staging`,
        method: 'put',
        data
    })
}

/**
 * 从缓存区中点击提交待审核
 * @param data
 * @return {AxiosPromise}
 */
export const putOrderSubmitApproval = (data) => {
    return request({
        url: `/api/order/order/submit-to-approval`,
        method: 'put',
        data
    })
}
/**
 * 从缓存区中点击提交待审核
 * @param data
 * @return {AxiosPromise}
 */
export const putFaireOrderSubmitApproval = (data) => {
    return request({
        url: `/api/faire-order/submit-to-approval`,
        method: 'put',
        data
    })
}

/**
 * 放置缓存区
 * @param {String} orderIdList
 * @return {AxiosPromise}
 */
export const putOrderPutStaging = (orderIdList) => {
    return request({
        url: `/api/order/order/put-staging`,
        method: 'put',
        data: {
            orderIdList: orderIdList.split(',')
        }
    })
}
/**
 * 放置缓存区
 * @param orderIdList
 * @return {AxiosPromise}
 */
export const putFaireOrderPutStaging = (orderIdList) => {
    return request({
        url: `/api/faire-order/put-staging`,
        method: 'put',
        data: {
            orderIdList: orderIdList.split(',')
        }
    })
}

/**
 * 应用最优运费试算
 * @param data:{
    "orderId": 0,
    "serviceName": "运输方式",
    "trialShippingAmount": 0, //运输试算金额
 * }
 * @returns {AxiosPromise}
 */
export const putOrderApplyFreight = (data) => {
    return request({
        url: `/api/order/order/apply-freight`,
        method: 'put',
        data
    })
}

/**
 * 获取子订单列表
 * @param parentId
 * @return {AxiosPromise}
 */
export const getOrderSubById = (parentId) => {
    return request({
        url: `/api/order/order/sub/${ parentId }`,
        method: 'get',
    })
}

/**
 * 一键拆单
 * @param id
 * @return {AxiosPromise}
 */
export const postOrderAutoSplitById = (id) => {
    return request({
        url: `/api/order/order/auto-split/${ id }`,
        method: 'post',
    })
}
/**
 * faire 一键拆单
 * @param id
 * @return {AxiosPromise}
 */
export const postFaireOrderAutoSplitById = (id) => {
    return request({
        url: `/api/faire-order/auto-split/${ id }`,
        method: 'post',
    })
}

/**
 * 手动拆单
 * @param {Object} data
 * {
 *   "id": 0,
 *   "orders": [
 *     {
 *       "items": [
 *         {
 *           "orderItemId": 0,
 *           "quantity": 0
 *         }
 *       ]
 *     }
 *   ]
 * }
 * @return {AxiosPromise}
 */
export const postOrderManualSplit = (data) => {
    return request({
        url: `/api/order/order/manual-split`,
        method: 'post',
        data
    })
}
/**
 * faire手动拆单
 * @param data
 * @return {AxiosPromise}
 */
export const postFaireOrderManualSplit = (data) => {
    return request({
        url: `/api/faire-order/manual-split`,
        method: 'post',
        data
    })
}

/**
 * 取消快速合并
 * @param data
 * @return {AxiosPromise}
 */
export const postCancelQuickMerge = (data) => {
    return request({
        url: `/api/order/order/cancel-quick-merge`,
        method: 'put',
        data
    })
}

/**
 * 智能物流基础数据
 * @param orderId
 * @return {AxiosPromise}
 */
export const getOrderIntelligentBaseData = (orderId) => {
    return request({
        url: `/api/order/order/intelligent-base-data/${orderId}`,
        method: 'get',
    })
}

/**
 * 订单标记为虚发状态的按钮操作
 * @param {Array} orderIdList
 * @return {AxiosPromise}
 */
export const postOrderFalseShipping = (orderIdList) => {
    return request({
        url: `/api/order/order/false-shipping`,
        method: 'post',
        data:{
            orderIdList
        }
    })
}


/**
 * 订单分票类型
 * @returns {AxiosPromise}
 */
export const getTicketSplitType = () => {
    return request({
        url: `/api/order/order/ticket-split-type`,
        method: 'get',
    })
}

/**
 * 手动清除异常状态 20240830新增状态修改
 * @param data
 * @return {AxiosPromise}
 */
export const cancelAbnormalStatus = (data) => {
    return request({
        url: `/api/order/order/cancelAbnormalStatus`,
        method: 'put',
        data
    })
}

/**
 * 区分原重寄接口（有逻辑调整） 20240902
 * @param data
 * @return {AxiosPromise}
 */
export const postReship = (data) => {
    return request({
        url: '/api/order/order/repost',
        method: 'post',
        data
    })
}
/**
 * 获取重寄订单编号 20240910
 * @param orderId
 * @return {AxiosPromise}
 */
export const getRepostOrderNumber = (orderId) => {
    return request({
        url: `/api/order/order/getRepostOrderNumber/${orderId}`,
        method: 'get',
    })
}



