import { postQuotationsTaskListByWorker} from '@/api/quotationsTask/blade-quotationsTask.js'

const quotation = {
    state: {
        taskInfo: {}
    },
    actions: {
        // 新版
        getTaskInfo({ commit }, taskId) {
            return new Promise((resolve, reject) => {
                commit('SET_TASK_INFO',{})
                postQuotationsTaskListByWorker(1, 10, {
                    taskId
                })
                .then(res => {
                    if ( res.data.code === 200 ) {
                        let data = res.data.data;
                        if(data.records.length){
                            commit('SET_TASK_INFO',data.records[0])
                            resolve(data.records[0])
                        }else{
                            reject();
                        }
                    }else{
                        reject();
                    }
                })
                .catch(err => {
                    reject();
                })

            })
        },
    },
    mutations: {
        SET_TASK_INFO: (state, data) => {
            state.taskInfo = data
        },
    }

};

export default quotation;
