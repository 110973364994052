// 任务报价模块 - 报价任务表接口
import request from '@/axios.js'

/**
 * 报价任务列表分页查询
 * @param current
 * @param size
 * @param data
 * @return {AxiosPromise}
 */
export const postQuotationsTaskList = (current, size, data) => {
    return request({
        url: '/api/blade-quotationsTask/quotationsTask/list',
        method: 'post',
        params: {
            current,
            size
        },
        data
    })
}

/**
 * 报价任务列表分页查询(工作人员)
 * @param current
 * @param size
 * @param data
 * @returns {AxiosPromise}
 */
export const postQuotationsTaskListByWorker = (current, size, data) => {
    return request({
        url: '/api/blade-quotationsTask/quotationsTask/listByWorker',
        method: 'post',
        params: {
            current,
            size
        },
        data
    })
}
/**
 * 修改报价任务状态为已发布
 * @param quotationsTaskId
 * @return {AxiosPromise}
 */
export const putUpdateStatusById = (quotationsTaskId) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/updateStatusById`,
        method: 'put',
        data:{
            quotationsTaskId
        }
    })
}

/**
 * 删除报价任务
 * @param id
 * @return {AxiosPromise}
 */
export const deleteRemoveById = (id) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/removeById/${id}`,
        method: 'delete',
    })
}
/**
 * 关闭报价任务
 * @param data.quotationsTaskId
 * @param data.notes
 * @param data.status
 * @return {AxiosPromise}
 */
export const putCloseQuotations = (data) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/closeQuotations`,
        method: 'put',
        data
    })
}
export const deleteRemoveByIds = (ids) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/remove`,
        method: 'post',
        params:{
            ids
        }
    })
}

/**
 * 更新生产新报价任务
 * @param id
 * @returns {AxiosPromise}
 */
export const getQuotationsTaskSaveTaskNew = (id) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/saveTaskNew`,
        method: 'get',
        params:{
            id
        }
    })
}

/**
 * 新增报价任务
 * @param data
 * @return {AxiosPromise}
 */
export const postSave = (data) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/saveTask`,
        method: 'post',
        data
    })
}

/**
 * 修改报价任务
 * @param data
 * @return {AxiosPromise}
 */
export const postUpdate = (data) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/update-task`,
        method: 'post',
        data
    })
}

/**
 * 选择供应商报价
 * @param inquiryProductIdList
 * @param quotationsTaskId
 * @return {AxiosPromise}
 */
export const getSelectSuppliersByIds = (inquiryProductIdList,quotationsTaskId) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/selectSuppliers`,
        method: 'put',
        data:{
            inquiryProductIdList,
            quotationsTaskId
        }
    })
}

/**
 * 生成报价结果
 * @param inquiryProductId
 * @returns {AxiosPromise}
 */
export const putQuotationsTaskQuotationResults = (inquiryProductId) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/quotationResults`,
        method: 'put',
        data:{
            inquiryProductId,
        }
    })
}
/**
 * 生成报价结果并推送
 * @param inquiryProductId
 * @return {AxiosPromise}
 */
export const putQuotationsTaskQuotationResultsAndSend = (inquiryProductId) => {
    return request({
        url: `/api/blade-quotationsTask/quotationsTask/quotationResultsAndSend`,
        method: 'put',
        data:{
            inquiryProductId,
        }
    })
}

/**
 * 报价任务导入功能
 */
export const postQuotationsTaskImport = '/api/blade-quotationsTask/quotationsTask/import';
/**
 * 导出模板
 */
export const getQuotationsTaskExportTemplate = '/api/blade-quotationsTask/quotationsTask/export-template';
