<template>
  <div class="avue-logo">
    <transition name="fade">
      <span v-if="keyCollapse"
            class="avue-logo_subtitle"
            key="0">
        <img src="/svg/logo.svg" alt="">
      </span>
    </transition>
    <transition-group name="fade">
      <template v-if="!keyCollapse">
        <!--        <div class="avue-logo_title" key="1"><img class="avue-logo_img" src="/img/logoname.png" alt=""></div>-->
        <div class="avue-logo_title" key="1">
          <img class="avue-logo_img" src="/svg/logo.svg" alt="">
          <img class="avue-logo_word" src="/svg/airlyle.svg" alt="">
        </div>
        <!--<span class="avue-logo_title" key="1">{{website.indexTitle}} </span>-->

      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "logo",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters(["website", "keyCollapse"])
  },
  methods: {}
};
</script>

<style lang="scss">
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.avue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: $sidebarTopLogoHeight;
  line-height: $sidebarTopLogoHeight;
  background-color: #12151F;
  font-size: 20px;
  overflow: hidden;
  box-sizing: border-box;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;
  &_title {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 20px;
    padding-left: 20px;
  }
  &_subtitle {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    img{
      width: 40px;
      vertical-align: middle;
    }
  }
  &_img{
    width: 40px;
    vertical-align: middle;
  }
  &_word{
    width: 68px;
    vertical-align: middle;
    margin-left: 4px;
  }
}
</style>
