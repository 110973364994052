/**
 * 全角数字转换为半角数字
 * @param str
 * @return {*}
 */
import calculation from "@/utils/calculation";

export function toHalfWidth(str) {
  return str.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  }).replace(/。/g, function(s) {
    return '.'
  });
}
/**
 * 输入框限制输入 只允许输入数字
 * 最大只允许保留num位小数（默认2位小数）
 * @param {String,Number} value: 0
 * @param {Number} num: 2
 * @return {string | Number}
 */
export function perfSumInput(value,num = 2) {
  if(num === 0){
    return isNaN(Math.trunc(value))?0:Math.trunc(value);
  }
  let temp = value.toString();
  temp = temp.replace(/。/g, ".");
  temp = temp.replace(/[^\d.]/g, "");
  temp = temp.replace(/^\./g, "");
  temp = temp.replace(/\.{2,}/g, "");
  temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  let reg = new RegExp("^(\\-)*(\\d+)\\.(\\d{0,"+num+"}).*$","gim");
  temp = temp.replace(reg, "$1$2.$3");
  if(temp.indexOf('.') != -1 && temp.indexOf('.') != temp.length -1){
    if(temp[0] === '0'){
      temp = Number(temp);
    }
  }
  if(temp[0] === '0' && temp[1] === '0'){
    temp = Number(temp);
  }
  return temp
}

/**
 * 四舍五入
 * @param value
 * @param num
 * @returns {number|number|number}
 */
export function perfSumRound(value,num = 2) {
  // let temp = perfSumInput(value,num)
  // let factor = Math.pow(10, num);
  // return Math.round(temp * factor) / factor;

  return Number(value.toFixed(num));
}


/** 美分转换美元 */
export const centsToDollar = (value)=> {
  return calculation.accDiv(value,100)
}
