import {perfSumInput, toHalfWidth} from '@/utils/number.js'


export function installDirective(Vue){
    // 滚动加载更多
    Vue.directive('loadMore', {
        bind(el, binding) {
            // 获取element，定义scroll
            let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
            select_dom.addEventListener('scroll', function () {
                let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
                if (height) {
                    binding.value()
                }
            })
        }
    });

    /**
     * @name 限制输入框只能输入数字
     */
    Vue.directive('limitInput', {
        bind(el, binding) {
            el.addEventListener('input', (e) => {
                let perf = e.data || 2;
                if(binding.value !== undefined && !isNaN(Number(binding.value))){
                    perf = Number(binding.value);
                }
                // 限制用户输入时使用全角输入法，还能过滤掉非数字字符，确保输入框中只包含数字
                let value = toHalfWidth(e.target.value);
                e.target.value = perfSumInput(value,perf);
                e.target.dispatchEvent(new Event('input')); // 更新v-model绑定的值
            })
        }
    });
}
