<template>
  <div class="basic-flex basic-flex2 flex flex-direction-column airlyle-table-box">
    <div>
      <div class="status-nav-box-2" :class="$attrs.headerNavClass" v-if="!options.hideHeader">
        <div class="status-nav-left">
          <ul v-if="options.headerFilter">
            <li v-for="(item,index) in options.tabOption.column"
                :key="index"
                @click="changeStatus(item)"
                class="overflow-1"
                :class="item.prop === options.typeName?'active':''">{{ item.label }}
            </li>
          </ul>
          <slot name="header-top-left"></slot>
        </div>
        <div class="status-nav-right">
          <slot name="search-top-right"></slot>
          <el-button type="warning" size="small" v-if="!options.hideColumnBtn" @click="viewColumnDialogFun" class="airlyle-nav-btn"
                     plain>
            <svg-icon icon-class="c_view"/>
            Columns
          </el-button>
        </div>
      </div>
      <div class="filter-box" v-if="!options.isHideFilter">
        <slot name="filters"></slot>
      </div>
      <div class="btn-config-box">
        <slot name="btnConfig"></slot>
      </div>

      <slot name="header-special"></slot>
    </div>
    <div class="table-main flex-item-1">
      <vxe-table ref="table"
                 v-bind="attrs"
                 header-row-class-name="table-main-v3-table-header">
        <vxe-column type="checkbox" width="60" v-if="options.checkbox" fixed="left"></vxe-column>
        <vxe-column type="seq" width="60" title="#" fixed="left" v-if="!options.isSeqHide"></vxe-column>
        <vxe-column type="expand" width="80" v-if="options.isShowExpand">
          <template #content="{ row }">
            <slot name="expandForm" :row="row"></slot>
          </template>
        </vxe-column>

        <template v-for="(config,index) in options.column" :key="index">
          <template v-if="config.colgroup">
            <slot :name="`${config.prop}Colgroup`" :row="config"></slot>
          </template>
          <template v-else>
            <vxe-column
              :key="config.prop"
              :field="config.prop"
              :title="config.label"
              :fixed="config.fixed"
              :width="config.width"
              :minWidth="config.minWidth"
              :visible="!config.hide"
              :sortable="config.sortable"
              :formatter="config.formatter"
              :showOverflow="config.showOverflow"
              :treeNode="config.treeNode"
              :titlePrefix="config.titlePrefix"
              :filters="config.filters">
              <template #default="{row}" v-if="config.slot">
                <slot :name="`${config.prop}Form`" :row="row"></slot>
              </template>
              <template #default="{row}" v-else-if="config.type === 'select'">
                <div v-for="item in config.dict" :key="item.value">
                  <div v-if="item.value === row[config.prop]" :key="item.value">{{ item.name }}</div>
                </div>
              </template>
            </vxe-column>
          </template>
        </template>
        <vxe-column :width="options.menuWidth?options.menuWidth:200" :title="options.columnTitle?options.columnTitle:'Menu'" fixed="right"
                    v-if="!options.columnHide">
          <template #default="{row,index}">
            <slot name="menu" :row="row" :index="index"></slot>
          </template>
        </vxe-column>
        <template #empty>
          <svg-icon icon-class="no-data" style="height: 78px;width: 78px;"/>
        </template>
      </vxe-table>
    </div>

    <div class="page" v-if="page">
      <vxe-pager
        v-if="page.total"
        :current-page="page.currentPage"
        :page-size="page.pageSize"
        :page-sizes="[10,20,50,100,500,1000]"
        :total="page.total"
        :layouts="['Total','Sizes','PrevPage', 'JumpNumber', 'NextPage', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </div>



    <dialog-public v-model="viewColumn.viewColumnDialog" width="886px">
      <template #title>Columns</template>

      <set-column v-if="viewColumn.viewColumnDialog" :options="options" ref="setColumnRef" :key="options.reload"/>
      <template #footer>
        <el-button type="warning" class="airlyle-wnn-btn" plain @click="viewColumnReset">Reset from default</el-button>
        <el-button type="primary" class="airlyle-wnn-btn" plain @click="viewColumnCancel">Cancel</el-button>
        <el-button type="primary" class="airlyle-wnn-btn"  @click="viewColumnSave">Save</el-button>
      </template>
    </dialog-public>
  </div>
</template>

<script setup>

import {inject, nextTick, onMounted, useAttrs} from 'vue'
// import SetColumn from "@/views/order/component/set-column.vue";
import SetColumn from "@/components/vxe/set-column.vue";
import { columnsInit } from "@/setup/vex/table.js";
import DialogPublic from "@/components/dialog/index.vue";

const props = defineProps({
  options: {
    type: Object,
    default: () => {}
  },
  search: {
    type: Object,
    default: () => {}
  },
  page: {
    type: Object,
    default: () => {}
  }
})
const $emit = defineEmits(['update:search', 'update:page','headerTypeName','onLoad'])
const attrs = useAttrs();
// console.log('attrs',attrs)

// 从祖节点接受项目配置项
const _this = inject("_this")
const table = ref();
const clearCheckboxRow = () =>{
  table.value.clearCheckboxRow();
}
const refreshColumn = () =>{
  let options = props.options;
  options.reload = Math.random();
  $emit('update:options',options)
  nextTick(() => {
    table.value.refreshColumn().then(() => table.value.refreshScroll())
  })
}
const changeStatus = (item) =>{
  props.options.typeName = item.prop;
  if (attrs.onHeaderTypeName) {
    // 如果父组件绑定了 header_type_name 事件，则触发该事件
    $emit('headerTypeName',props.options.typeName);
  } else {
    // 否则执行其他逻辑
    props.page.currentPage = 1;
    let page = props.page
    page.currentPage = 1;
    $emit('update:page', page);

    // _this.onLoad(page);
    onLoad(page);
  }
}

const onLoad = (page)=>{
  if(_this.onLoad){
    _this.onLoad(page);
  }
  else{
    $emit('onLoad',page)
  }
}


const handlePageChange = ({ currentPage, pageSize }) =>{
  let page = props.page
  page.currentPage = currentPage;
  page.pageSize = pageSize;
  $emit('update:page', page);
  // _this.onLoad(page);
  onLoad(page);
}


let {
    viewColumn,
    setColumnRef,
    viewColumnDialogFun,
    viewColumnCancel,
    viewColumnSave,
    viewColumnReset
} = columnsInit(props.options)


onMounted(()=>{
  // 如果需要初始化参数 则禁止加载后自动请求接口
  if(!props.options.isDisableOnLoad){
    // _this.onLoad(props.page);
    onLoad(props.page);
  }

})

defineExpose({
  table,
  clearCheckboxRow,
  refreshColumn
})
</script>

<style scoped lang="scss">

.basic-flex {
  //display: flex;
  //flex-direction: column;
  height: 100%;
  text-align: left;
  background: $mainBgColor;

  .table-main {
    flex-grow: 1;
    overflow-y: hidden;
    padding: 0 16px;
    background: #fff;
      min-height:400px;
  }

  .page {
    height: 50px;
    padding: 0;
    margin-top: 1px;
  }

  .vxe-table--render-default {
    overflow: hidden;
  }
  .filter-box{
    :deep(.el-form-item){
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}

.airlyle-table-box{
  min-height: 400px;
}
</style>
