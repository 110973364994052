import { onMounted, reactive, useAttrs, useSlots , ref } from 'vue'
import { useStore } from 'vuex'
import calculation from '@/utils/calculation'

/**
 * 自定义列表-初始化模块
 * @param opt
 * @return {{changeStatus: changeStatus, handlePageChange: handlePageChange}}
 */
export const wuliuTableInit = (opt)=>{
	const {$emit} = opt;
	const $attrs = useAttrs();

	const changeStatus = (item)=>{
		$attrs.typeName = item.prop;
		$attrs.pages.pageNo = 1;
		$attrs.onLoad($attrs.pages);
	}

	const handlePageChange = (params)=>{
		let { currentPage, pageSize } = params;
		// $emit('pageChange',params);
		$attrs.pages.pageNo = currentPage;
		$attrs.pages.pageSize = pageSize;
		$attrs.onLoad($attrs.pages);
	}

	const handlePageStart = ()=>{
		console.log('handlePageStart')
		$attrs.pages.pageNo = 1;
		$attrs.onLoad($attrs.pages);
	}

	const handlePageEnd = ()=>{
		let total = $attrs.tablePage.total;
		let size = $attrs.pages.pageSize;
		$attrs.pages.pageNo = Math.ceil(calculation.accDiv(total,size));
		$attrs.onLoad($attrs.pages);
	}

	const handlePageMoreClick = (buttons)=>{
		let { name } = buttons;
		switch ( name ){
			case 'start':
				handlePageStart();
				break;
			case 'end':
				handlePageEnd();
				break;
		}

	}

	return {
		changeStatus,
		handlePageChange,
		handlePageMoreClick
	}
}

/**
 * 视图配置-初始化模块
 */
export const columnsInit = (options)=>{

	const store = useStore()
	console.log('store',store.state);
	const tableStore =  store.state.tableColumn

	let columns = options.column

	const viewColumn = reactive({
		viewColumnDialog:false,
		defaultListColumns:columns
	})

	// 打开弹框
	const viewColumnDialogFun = ()=>{
		viewColumn.viewColumnDialog = true;
	}

	// 关闭弹框
	const viewColumnCancel = ()=>{
		let columns = tableStore.tableColumn && tableStore.tableColumn[name];
		let value = viewColumn.defaultListColumns;

		publicSetColumn(columns?columns:value)
	}

	const setColumnRef = ref();

	// 保存视图配置参数
	const viewColumnSave = ()=>{
		let list1 = setColumnRef.value.list1.map((item,index)=>{
			item.order = index;
			return item;
		})
		let list2 = setColumnRef.value.list2.map((item,index)=>{
			item.order = list1.length + index + 1;
			return item;
		})
		let value = list1.concat(list2);

		console.log('save---',value);
		publicSetColumn(value)
	}

	// 默认视图配置参数
	const viewColumnReset = ()=>{
		let value = viewColumn.defaultListColumns;
		publicSetColumn(value)
	}

	// 公共设置
	const publicSetColumn = (value)=>{
		viewColumn.viewColumnDialog = false;
		store.dispatch('setTableColumn',{
			name:options.columnName,
			value: {
				...options,
				column:value
			}
		})
	}

	return {
		viewColumn,
		setColumnRef,
		viewColumnDialogFun,
		viewColumnCancel,
		viewColumnSave,
		viewColumnReset
	}
}
