import website from '@/config/website';

const modules = import.meta.glob('../**/**/*.vue');

function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s);
}

// 将参数处理为参数的形式拼接
function objToform(obj) {
  let result = [];
  Object.keys(obj).forEach(ele => {
    result.push(`${ele}=${obj[ele]}`);
  })
  return result.join('&');
}

let RouterPlugin = function () {
  this.$router = null;
  this.$store = null;
};
RouterPlugin.install = function (option = {}) {
  this.$router = option.router;
  this.$store = option.store;
  let i18n = option.i18n.global;
  this.$router.$avueRouter = {
    //全局配置
    $website: this.$store.getters.website,
    group: '',
    meta: {},
    safe: this,
    // 设置标题
    setTitle: title => {
      const defaultTitle = i18n.t('title');
      title = title ? `${title} | ${defaultTitle}` : defaultTitle;
      document.title = title;
    },
    closeTag: value => {
      let tag = value || this.$store.getters.tag;
      if (typeof value === 'string') {
        tag = this.$store.getters.tagList.find(ele => ele.fullPath === value);
      }
      this.$store.commit('DEL_TAG', tag);
    },
    generateTitle: (title, key) => {
      // console.log('generateTitle',title);
      // let query = item[props.query || 'query'] || {};
      // let title = query.name || item[props.label || 'label'];
      // let meta = item[props.meta || 'meta'] || {};
      // let key = meta.i18n;
      // if (key) {
      //   const hasKey = i18n.te('route.' + key);
      //   if (hasKey) return i18n.t('route.' + key);
      // }
      // return title;

      if (!key) return title;
      const hasKey = i18n.te('route.' + key)
      if (hasKey) {
        return i18n.t('route.' + key)
      }
      return title
    },
    //处理路由
    getPath: function (params) {
      let {src} = params;
      let result = src || '/';
      if (isURL(src)) {
        result = `/myiframe/urlPath?${objToform(params)}`;
      }
      return result;
    },
    //正则处理路由
    vaildPath: function (list, path) {
      let result = false;
      list.forEach(ele => {
        if (new RegExp("^" + ele + ".*", "g").test(path)) {
          result = true
        }

      })
      return result;
    },
    //设置路由值
    getValue: function (route) {
      let value = "";
      if (route.query.src) {
        value = route.query.src;
      } else if(route.meta.activeMenu) {
        value = route.meta.activeMenu;
      } else {
        value = route.path;
      }
      return value;
    },
    //动态路由
    formatRoutes: function (aMenu = [], first) {
      const aRouter = [];
      const propsDefault = website.menu.props;
      // 如果没有权限菜单就结束
      if (aMenu && aMenu.length === 0) return;
      // 开始处理menu
      for (let i = 0; i < aMenu.length; i++) {
        // 取到当前要处理的一项
        const oMenu = aMenu[i];
        let path = oMenu[propsDefault.path],
        // let path = (() => {
        //     if (first) {
        //       // 将 '/index' 替换为 ''
        //       return oMenu[propsDefault.path].replace('/index', '')
        //     } else {
        //       return oMenu[propsDefault.path]
        //     }
        //   })(),
          isComponent = true,
          component = oMenu.path,
          name = oMenu[propsDefault.label],
          icon = oMenu[propsDefault.icon],
          children = oMenu[propsDefault.children],
          query = oMenu[propsDefault.query],
          remark = oMenu[propsDefault.remark],
          meta = oMenu[propsDefault.meta] || {},
          keepAlive = !!oMenu.isCatch || false,
          alias = oMenu[propsDefault.alias];
        // console.log('oMenu---',oMenu.name,'---',keepAlive);
        if (keepAlive) {
          meta.keepAlive = keepAlive;
        }
        // meta中 keepalive 的处理
        // console.log('meta中 keepalive 的处理',meta);
        meta = Object.assign(meta, (function () {
          // console.log(meta)
          // console.log(remark)
          let isAlias = remark && remark.indexOf('别名') !== -1;
          // console.log(oMenu.remark.indexOf('缓存'))
          return {
            keepAlive: keepAlive,
            title: isAlias?alias:name,
          }
        })());
        const isChild = !!(children && children.length !== 0);
        const oRouter = {
          path: path,
          name: name,
          component: (() => {
            // 判断是否为首路由
            if (first) {
              return modules[
                option.store.getters.isMacOs
                  ? '../page/index/layout.vue'
                  : '../page/index/index.vue'
              ];
              // 判断是否为多层路由
            } else if (isChild && !first) {
              return modules['../page/index/layout.vue'];
              // 判断是否为最终的页面视图
            } else {
              let result = modules[`../views${component}.vue`];
              if (result) result().then(mod => (mod.default.name = path));
              else {
                isComponent = false;
              }
              return result;
            }
          })(),
          icon,
          meta,
          query,
          redirect: (() => {
            if (!isChild && first) return `${path}`;
            else return '';
          })(),
          // 处理是否为一级路由
          children: !isChild
            ? (() => {
                if (first) {

                  // 这里的isURL判断，因为这个网站有使用 iframe。所以需要判断是否为网页链接
                  if (!isURL(path)) oMenu[propsDefault.path] = `${path}`;
                  // oMenu[propsDefault.path] = `${path}`;
                  // console.log('component',component);
                  // 临时解决 文件夹下面只有index.vue的时候
                  if(['/sourcing_month','/inventory_value'].includes(component)) component = component+'/index';

                  let result = modules[`../views${component}.vue`];
                  // console.log('inventory_value',path);
                  if (result) result().then(mod => (mod.default.name = path));
                  else {
                    isComponent = false;
                  }
                  return [
                    {
                      component: result,
                      icon: icon,
                      name: name,
                      meta: meta,
                      query: query,
                      path: '',
                    },
                  ];
                }
                return [];
              })()
            : (() => {
                return this.formatRoutes(children, false);
              })(),
        };
        if (!isURL(path) && isComponent) aRouter.push(oRouter);
      }
      if (first) {
        aRouter.forEach(ele => this.safe.$router.addRoute(ele));
      } else {
        return aRouter;
      }
    },
  };
};
export const formatPath = (ele, first) => {
  const propsDefault = website.menu.props;
  const icon = ele[propsDefault.icon];
  ele[propsDefault.icon] = !icon ? propsDefault.iconDefault : icon;
  // ele.meta = { keepAlive: ele.isOpen === 2 };
  ele.meta = { keepAlive: ele.isCatch === 1 };
  // console.log('formatPath---',ele);
  const iframeComponent = 'components/iframe/main';
  const iframeSrc = href => {
    return href.replace(/&/g, '#');
  };
  const isChild = !!(ele[propsDefault.children] && ele[propsDefault.children].length !== 0);
  if (!isChild && first) {
    ele.component = 'views' + ele[propsDefault.path];
    if (isURL(ele[propsDefault.href])) {
      let href = ele[propsDefault.href];
      ele.component = iframeComponent;
      ele[propsDefault.query] = { url: iframeSrc(href) };
    }
  } else {
    ele[propsDefault.children] &&
      ele[propsDefault.children].forEach(child => {
        child.component = 'views' + child[propsDefault.path];
        // child.meta = { keepAlive: child.isOpen === 2 };
        child.meta = { keepAlive: child.isCatch === 1 };
        if (isURL(child[propsDefault.href])) {
          let href = child[propsDefault.href];
          child[propsDefault.path] = ele[propsDefault.path] + '/' + child.code;
          child.component = iframeComponent;
          child[propsDefault.query] = { url: iframeSrc(href) };
        }
        formatPath(child);
      });
  }
};
export default RouterPlugin;
