import {getStore, setStore} from '@/utils/store';

const dict = {
    state: {
        scanning_fnsku: '',
    },
    actions: {
    },
    mutations: {
        SET_SCANNING_FNSKU: (state, data) => {
            state.scanning_fnsku = data;
        },
    }

};

export default dict;
