import { deepClone } from '@/utils/util';
import { validatenull } from '@/utils/validate';
import { setStore } from '@/utils/store';

const getters = {
  tag: state => state.tags.tag,
  language: state => state.common.language,
  setting: state => state.common.setting,
  website: state => state.common.website,
  userInfo: state => state.user.userInfo,
  themeName: state => state.common.themeName,
  isMacOs: (state, getters) => getters.themeName === 'mac-os',
  isRefresh: state => state.common.isRefresh,
  isSearch: state => state.common.isSearch,
  isHorizontal: state => state.common.setting.sidebar === 'horizontal',
  isCollapse: state => state.common.isCollapse,
  isLock: state => state.common.isLock,
  isFullScren: state => state.common.isFullScren,
  isMenu: state => state.common.isMenu,
  lockPasswd: state => state.common.lockPasswd,
  tagList: state => state.tags.tagList,
  tagsKeep: (state, getters) => {
    console.log('getters.tagList',getters.tagList);
    console.log('getters.tagList-filter',getters.tagList
      .filter(ele => {
        return (ele.meta || {}).keepAlive;
      })
      .map(ele => ele.value));
    return getters.tagList
      .filter(ele => {
        return (ele.meta || {}).keepAlive;
      })
      .map(ele => ele.value);
  },
  tagWel: state => state.tags.tagWel,
  token: state => state.user.token,
  roles: state => state.user.roles,
  permission: state => state.user.permission,
  menuId: state => state.user.menuId,
  menu: state => state.user.menu,
  menuAll: state => state.user.menuAll,
  logsList: state => state.logs.logsList,
  logsLen: state => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  flowRoutes: state => state.dict.flowRoutes,

  colorName: state => state.common.colorName,
  isShade: state => state.common.isShade,

  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : false,
  screen: state => state.common.screen,
  dict: state => state.dict,

  brandAll: state => state.omsProduct.brandAll,
  attrAll: state => state.omsProduct.attrAll,
  workorder_title: state => state.workorder.workorder_title,
  // 工单数据统计
  workorders_statistics_store_list: state => state.workorder.workorders_statistics_store_list,
  workorders_statistics_store_week_list: state => state.workorder.workorders_statistics_store_week_list,
  // 新手引导-order
  guide_order: state => state.guide.order,
  // 新手引导-product
  guide_product: state => state.guide.product,
  // 新手引导-全屏:
  guideShow: state => state.guide.guideShow,
  guideIsShow: state => state.guide.guideIsShow,
  businessDict: state => state.businessDict.businessDict,
  // 各页面视图配置
  tableColumn: state => {
    let tableData = deepClone(state.tableColumn.tableColumn);
    Object.keys(tableData).forEach(item=>{
      tableData[item].column = tableData[item].column.sort((a,b)=> a.order - b.order);
    })
    return tableData;
  },
  // 判断列表字段取缓存还是默认值
  decideViewColumnOrOrigin : state => (origin)=>{
    let tableData = deepClone(state.tableColumn.tableColumn);
    console.log('tableData[item]',tableData);
    Object.keys(tableData).forEach(item=>{
      console.log('tableData[item]',item);
      console.log('tableData[item]',tableData[item]);
      tableData[item].column = tableData[item].column.sort((a,b)=> a.order - b.order);
    })
    // 判断是否有缓存，有的话返回缓存的值，没有的话返回默认值
    if(!validatenull(tableData) && tableData[origin.columnName]){
      // 判断缓存值版本是否是最新的
      if(tableData[origin.columnName].lastUpdateVersion !== origin.lastUpdateVersion){
        // 如果不是最新的则删除本地缓存，并返回默认值
        delete tableData[origin.columnName]
        setStore({ name: 'tableColumn', content: tableData });
        return origin
      }
      return tableData[origin.columnName];
    }else{
      return origin;
    }
  },
  filtersDefault: state => state.tableColumn.filtersDefault,

  // 扫描fnsku的fnsku值
  scanning_fnsku: state => state.returnLabel.scanning_fnsku,
  // 获取物流公司字典
  PTLogisticsList: state => state.dict.PTLogisticsList,
  // quotation_previewInfo: state => state.quotation.previewInfo,
  // 查询标准物流线路时 新增自定义物流名称及线路
  quotation_logistics_autoNameAndLine: state => state.quotationLogistics.logisticsAutoNameAndLine,

  // 店铺列表
  getStoreList: state => state.storeNameList.storeList,
  quotation_taskInfo: state => state.quotation.taskInfo,

  // order 列表
  order_detailInfo: state => state.ordersList.orderInfo,
  order_statusInfo: state => state.ordersList.statusInfo,
};
export default getters;
