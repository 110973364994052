// 店铺列表

import {getAvailableStore} from '@/api/store/storeinfo.js'

const storeNameList = {
    state: {
        storeList: []
    },
    actions: {
        // 获取店铺列表
        getStoreList({commit}){
            return  new Promise((resolve,reject)=>{
                getAvailableStore()
                    .then(res => {
                        if( res.data ){
                            commit('SET_DATA',res.data.data)
                            resolve()
                        }
                    })
                    .catch(()=>{
                        resolve([])
                    })
            })
        },
    },
    mutations: {
        SET_DATA: (state, data) => {
            state.storeList = data
        },
    }

};

export default storeNameList;
