import { getDetail } from '@/api/order/order';
import { setStore, getStore, removeStore } from '@/utils/store';

const ordersList = {
  state: {
    orderInfo: {},
    statusInfo: getStore({name: 'statusInfo'})  || {}
  },
  actions: {
    //
    getOrderInfo({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        commit('SET_ORDER_INFO',{})
        getDetail(orderId)
          .then(res => {
            if ( res.data.code === 200 ) {
              if(res.data.data) {
                commit('SET_ORDER_INFO',res.data.data)
                resolve(res.data.data)
              } else {
                reject();
              }
            }else{
              reject();
            }
          })
          .catch(err => {
            reject(err);
          })

      })
    },
  },
  mutations: {
    SET_ORDER_INFO: (state, data) => {
      state.orderInfo = data
    },
    SET_STATUS_INFO: (state, data) => {
      state.statusInfo = data
      setStore({
        name: 'statusInfo',
        content: state.statusInfo,
        type: 'session'
      })
    },
    CLEAR_STATUS: (state) => {
      state.statusInfo = {};
      removeStore({
        name: 'statusInfo',
        type: 'session'
      });
    },
  }

};

export default ordersList;
