import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router';
import PageRouter from './page/';
import ViewsRouter from './views/';
import AvueRouter from './avue-router';
import i18n from '@/lang';
import Store from '@/store/';
//创建路由
const Router = createRouter({
  base: import.meta.env.VITE_APP_BASE,
  history: createWebHashHistory(),
  // history: createWebHistory(import.meta.env.VITE_APP_BASE),
  routes: [...PageRouter, ...ViewsRouter],
});
AvueRouter.install({
  store: Store,
  router: Router,
  i18n: i18n,
  // 强制都缓存
  // keepAlive: true,
  // keepAlive: false,
});

console.log('outer.$avueRouter.formatRoutes(Store.getters.menuAll,',Store.getters.menuAll);
Router.$avueRouter.formatRoutes(Store.getters.menuAll, true);

// 重置路由 白名单
// let whiteList = [...PageRouter, ...ViewsRouter].map(item=>item.name)
export function resetRouter() {
  // 重置路由 比如用于身份验证失败，需要重新登录时 先清空当前的路有权限
  const newRouter = createRouter({
    base: import.meta.env.VITE_APP_BASE,
    history: createWebHashHistory(),
    // history: createWebHistory(import.meta.env.VITE_APP_BASE),
    routes: [...PageRouter, ...ViewsRouter],
  });
  Router.matcher = newRouter.matcher; // reset router
  AvueRouter.install({
    router: Router,
    store: Store,
    i18n: i18n,
  });
  // console.log('重置路由')
  // 重置路由 比如用于身份验证失败，需要重新登录时 先清空当前的路有权限
  // console.log('Store.getters.menuAll',Store.getters.menuAll);
  // //获取所有路由
  // Router.getRoutes().forEach((route) => {
  //   const { name } = route;   //获取路由name
  //   console.log('获取路由name',name)
  //
  //   // if (name && !whiteList.includes(name)) {      //路由不属于白名单,则删除
  //   // // if (name) {      //路由不属于白名单,则删除
  //   //   Router.hasRoute(name) && Router.removeRoute(name);
  //   // }
  //   // 将白名单全部重新添加进去
  //   // [...PageRouter, ...ViewsRouter].map(item=>{
  //   //   Router.addRoute(item)
  //   // })
  // });
}

export default Router;
