<script setup name="svg-icon">
import { Icon } from '@iconify/vue'
import { computed , unref} from 'vue'

const props = defineProps({
    iconClass: {
        type: String,
        required: true
    },
    className: {
        type: String,
        required: false
    },
})

const name = computed(()=>{
  return `#icon-${props.iconClass}`
})

const svgClass = computed(()=>{
  if (props.className) {
    return 'svg-icon ' + props.className;
  } else {
    return 'svg-icon';
  }
})


</script>

<template>
	<svg :class="svgClass" aria-hidden="true">
		<use :xlink:href="`${name}`" />
	</svg>
</template>

<style lang="scss" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor; //此属性为更改svg颜色属性设置
  stroke: none;
  overflow: hidden;
  vertical-align: middle;
}
</style>
