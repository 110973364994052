import Layout from '@/page/index/index.vue'

export default [
  {
    path: '/login',
    name: '登录页',
    component: () =>
      import( /* webpackChunkName: "login" */ '@/page/login/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/register',
    name: '注册页',
    component: () =>
      import( /* webpackChunkName: "login" */ '@/page/register/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/forget',
    name: '忘记密码',
    component: () =>
      import( /* webpackChunkName: "login" */ '@/page/forget/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  // {
  //   path: '/help',
  //   name: '用户手册',
  //   component: () =>
  //     import( /* webpackChunkName: "help" */ '@/page/help/index.vue'),
  //   meta: {
  //     title:'Help',
  //     keepAlive: true,
  //     isTab: false,
  //     isAuth: false
  //   }
  // },
  {
    path: '/quotation_result/:productCode/:taskNum/:randomCode',
    name: '报价管理-报价列表-报价单',
    component: () =>
      import( /* webpackChunkName: "quotation-result" */ '@/views/airOMS/quotation/result/quotation-result.vue'),
    meta: {
      title:'Quotation of prices',
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/quotation_preview_result/:taskNum',
    name: '报价管理-报价列表-报价单-预览',
    component: () =>
      import( /* webpackChunkName: "quotation-result" */ '@/views/airOMS/quotation/result/quotation-result.vue'),
    meta: {
      title:'Quotation of prices',
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/terms-and-privacy',
    name: '法律条款和隐私条款',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/terms/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/lock',
    name: '锁屏页',
    component: () =>
      import( /* webpackChunkName: "lock" */ '@/page/lock/index.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/print_box_number',
    name: '打印箱号',
    component: () =>
      import( /* webpackChunkName: "print" */ '@/views/print/fnsku_box_number.vue'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "404" */ '@/components/error-page/404.vue'),
    name: '404',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }

  },
  {
    path: '/403',
    component: () =>
      import( /* webpackChunkName: "404" */ '@/components/error-page/403.vue'),
    name: '403',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/500',
    component: () =>
      import( /* webpackChunkName: "404" */ '@/components/error-page/500.vue'),
    name: '500',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/agreement/privacy',
    component: () =>
      import( /* webpackChunkName: "404" */ '@/page/agreement/privacy.vue'),
    name: 'agreement_privacy',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/',
    name: '主页',
    redirect: '/wel'
  },
  {
    path: '/myiframe',
    component: Layout,
    redirect: '/myiframe',
    children: [{
      path: ":routerPath",
      name: 'iframe',
      component: () =>
        import( /* webpackChunkName: "myiframe" */ '@/components/iframe/main.vue'),
      props: true
    }]

  }
]
