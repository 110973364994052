<template>
  <div class="sidebar-icon">
    <template v-if="getIconType(icon) === 'icon'">
      <i :class="icon" v-if="icon"></i>
    </template>
    <template v-else-if="getIconType(icon) === 'airlyle_icon'">
      <svg class="airlyle_sidebar_icon" aria-hidden="true">
        <use :xlink:href="`${ icon }`"></use>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: "sidebar-icon",
  props:{
    icon: String
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    getIconType(icon){
      if(icon && icon.includes('#')){
        return 'airlyle_icon'
      }else{
        return 'icon'
      }
    },
    // 格式化airlyle菜单图标
    formatIconSvg(iconName){
      if(iconName.includes('#')){
        return iconName.replace('iconfont_airlyle ','');
      }
      return iconName
    },
  }
}
</script>

<style scoped lang="scss">
.sidebar-icon{
  display: inline-block;
  vertical-align: top;
}

.airlyle_sidebar_icon{
  vertical-align: middle;
}
</style>
