import { getStore, setStore } from '@/utils/store';

import { getDictionary } from '@/api/system/dict';
import {getAllExpressCode, getDictionary as getDictionaryBiz} from '@/api/system/dictbiz'

const dict = {
  state: {
    flowRoutes: getStore({ name: 'flowRoutes' }) || {},
    PTLogisticsList: getStore({ name: 'PTLogisticsList' }) || {},
    warehouseVendorTypeList: [],
    countryCodeList: [],
    partitionTypeList: [],
    expressNameList: [],
    expressCodeList: [],
    productAttrList: [],
  },
  actions: {
    FlowRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getDictionary({ code: 'flow' }).then(res => {
          commit('SET_FLOW_ROUTES', res.data.data);
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 物流公司dict
    PTLogistics({ commit }) {
      return new Promise((resolve, reject) => {
        getDictionaryBiz({ code: 'PTLogistics' }).then(res => {
          commit('SET_PTLOGISTICS', res.data.data);
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 库存管理-供应商类型
    getWarehouseVendorType({ state,commit }) {
      return new Promise((resolve, reject) => {
        if(state.warehouseVendorTypeList.length === 0){
          getDictionaryBiz({ code: 'warehouse_vendor_type' }).then(res => {
            commit('SET_VENDOR_TYPE', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        }else{
          resolve();
        }
      })
    },
    // 获取报价模块-基础运费维护-相关字典
    getBasicFreightMaintenanceDict({ state,commit }){
      try {
        let countryCode = new Promise((resolve, reject) => {
          getDictionaryBiz({ code: 'country_code' }).then(res => {
            commit('SET_COUNTRYCODE', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        })
        let partitionType = new Promise((resolve, reject) => {
          getDictionaryBiz({ code: 'partition_type' }).then(res => {
            commit('SET_PARTITIONTYPE', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        })
        let expressName = new Promise((resolve, reject) => {
          getDictionaryBiz({ code: 'express_name' }).then(res => {
            commit('SET_EXPRESSNAME', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        })
        let expressCode = new Promise((resolve, reject) => {
          getAllExpressCode().then(res => {
            commit('SET_EXPRESSCODE', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        })
        let productAttr = new Promise((resolve, reject) => {
          getDictionaryBiz({ code: 'product_attr' }).then(res => {
            commit('SET_PRODUCTATTR', res.data.data);
            resolve();
          }).catch(error => {
            reject(error)
          })
        })

        return Promise.all([countryCode, partitionType, expressName, productAttr])
      }catch ( e ){
        console.error(e.message)
      }
    }
  },
  mutations: {
    SET_FLOW_ROUTES: (state, data) => {
      state.flowRoutes = data.map(item => {
        return {
          routeKey: `${item.code}_${item.dictKey}`,
          routeValue: item.remark,
        };
      });
      setStore({ name: 'flowRoutes', content: state.flowRoutes });
    },
    SET_PTLOGISTICS: (state, data) => {
      state.PTLogisticsList = data;
      setStore({ name: 'PTLogisticsList', content: data })
    },
    //  库存管理-供应商类型
    SET_VENDOR_TYPE: (state, data) => {
      state.warehouseVendorTypeList = data;
    },
    //  获取报价模块-基础运费维护-相关字典
    SET_COUNTRYCODE: (state, data) => {
      state.countryCodeList = data;
    },
    //  获取报价模块-基础运费维护-相关字典
    SET_PARTITIONTYPE: (state, data) => {
      state.partitionTypeList = data;
    },
    //  获取报价模块-基础运费维护-相关字典
    SET_EXPRESSNAME: (state, data) => {
      state.expressNameList = data;
    },
    //  获取报价模块-基础运费维护-相关字典
    SET_EXPRESSCODE: (state, data) => {
      state.expressCodeList = data;
    },
    //  获取报价模块-基础运费维护-相关字典
    SET_PRODUCTATTR: (state, data) => {
      state.productAttrList = data;
    },
  },
};

export default dict;
