<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'||index==levelList.length-1"
              class="no-redirect">{{ getTitle(item) }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ getTitle(item) }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
  import {compile} from 'path-to-regexp'

  export default {
    data() {
      return {
        levelList: null
      }
    },
    watch: {
      $route(route) {
        // if you go to the redirect page, do not update the breadcrumbs
        if (route.path.startsWith('/redirect/')) {
          return
        }
        this.getBreadcrumb()
      }
    },
    created() {
      this.getBreadcrumb()
    },
    methods: {
      getTitle(item){
        return item.meta.title?item.meta.title:item.name
      },
      getBreadcrumb() {
        // only show routes with meta.title
        let matched = this.$route.matched.filter(item => item.name)
        // const first = matched[0]

        // if (!this.isDashboard(first)) {
        //   matched = [{ path: '/dashboard', meta: { title: 'Dashboard' }}].concat(matched)
        // }
        this.levelList = matched.filter(item => item.name);

      },
      isDashboard(route) {
        const name = route && route.name
        if (!name) {
          return false
        }
        return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
      },
      pathCompile(path) {
        // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
        const {params} = this.$route
        var toPath = compile(path)
        return toPath(params)
      },
      handleLink(item) {
        const {redirect, path} = item
        // console.log(item);
        if (redirect) {
          this.$router.push(redirect)
          return
        }
        this.$router.push(this.pathCompile(path))
      }
    }
  }
</script>

<style lang="scss" scoped>

  .app-breadcrumb.el-breadcrumb {
    font-size: 16px;
    height: $topNavHeight;
    line-height: $topNavHeight;
    background: transparent;
    /*margin-top: 35px;*/
    position: relative;
    //&:after{
    //  content: '';
    //  width: 100%;
    //  height: 2px;
    //  background: #064B9E;
    //  position: absolute;
    //  bottom: 0;
    //  left: 0;
    //}
    .no-redirect {
      color: $main;
      font-weight: 400;
      cursor: text;
    }
  }
  :deep(.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link){
    color: #333;
    &:hover{
      color: $main;
    }
  }
</style>
