import Cookies from 'js-cookie';

const TokenKey = 'airlyle-access-token'
const RefreshTokenKey = 'airlyle-refresh-token'
const SessionId = 'JSESSIONID';
const UserId = 'b-user-id';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token);
}

export function removeToken() {
  Cookies.remove(SessionId);
  Cookies.remove(UserId);
  return Cookies.remove(TokenKey);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}

/**
 * 根据菜单权限遍历获取按钮级别字段
 * @param list
 * @return {*[]}
 */
export function getButtonsCode(list) {
  let result = [];
  function getCode(list) {
    list.forEach(ele => {
      if (typeof (ele) === 'object') {
        const chiildren = ele.children;
        const code = ele.code;
        if (chiildren) {
          getCode(chiildren)
        } else {
          result.push(code);
        }
      }
    })
  }
  getCode(list)

  return result
}
