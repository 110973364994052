<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
