import request from '@/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/api/store/store-info/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/api/store/store-info/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/api/store/store-info/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/api/store/store-info/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/api/store/store-info/update',
    method: 'post',
    data: row
  })
}

export const postRelate = (row) => {
  return request({
    url: '/api/store/store-info/shopify/relate',
    method: 'post',
    data: row
  })
}

export const postEnableDisable = (id) => {
  return request({
    url: `/api/store/store-info/enable-disable/${ id }`,
    method: 'post',
  })
}

/**
 * 列出可用店铺
 * @returns {AxiosPromise}
 */
export const getAvailableStore = () => {
  return request({
    url: '/api/store/store-info/available-store',
    method: 'get',
  })
}

/**
 * 列出可用店铺(真实)
 * @returns {AxiosPromise}
 */
export const getAvailableRealStore = () => {
  return request({
    url: '/api/store/store-info/available-real-store',
    method: 'get',
  })
}

/**
 * 根据平台类型列出可用店铺
 * @param syncToPlatformType 2
 * @returns {AxiosPromise}
 */
export const getStoreByPlatformType = (syncToPlatformType) => {
  return request({
    url: '/api/store/store-info/getStoreByPlatformType',
    method: 'get',
    params:{
      syncToPlatformType
    }
  })
}

/**
 * 获取商户基本信息
 * @returns {AxiosPromise}
 */
export const getStoreInfoBasicMerchantInfoByUserId = (userId) => {
  return request({
    url: `/api/store/store-info/basic-merchant-info/${ userId }`,
    method: 'get',
  })
}
/**
 * 更新商户基本信息
 * @param data
 * @return {AxiosPromise}
 */
export const putStoreInfoBasicMerchantInfo = (data) => {
  return request({
    url: `/api/store/store-info/update-merchant-basic-info`,
    method: 'put',
    data
  })
}

/**
 * 获取服务类型
 * @returns {AxiosPromise}
 */
export const getStoreInfoServiceTypes = () => {
  return request({
    url: `/api/store/store-info/service-types`,
    method: 'get',
  })
}
/**
 * 更新商户业务
 * @returns {AxiosPromise}
 */
export const putStoreInfoMerchantBusiness = (data) => {
  return request({
    url: `/api/store/store-info/merchant-business`,
    method: 'put',
    data
  })
}

/**
 * 全局判断店铺是否授权
 * @returns {AxiosPromise}
 */
export const getStoreInfoAvailableStoreUrl = () => {
  return request({
    url: `/api/store/store-info/available-store-url`,
    method: 'get',
  })
}
