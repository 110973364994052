<template>
  <div class="menu-wrapper">
    <template v-for="(item,index) in menu"  :key="index">
      <el-menu-item v-if="validatenull(item[childrenKey]) && vaildRoles(item)"
                    :index="item[pathKey]"
                    @click="open(item)"
                    popper-class="airlyle-sidebar"
                    :key="item[labelKey]"
                    :class="{'is-active':vaildActive(item)}">
        <sidebar-icon :icon="item[iconKey]" />
        <template #title><span>{{generateTitle(item)}}</span></template>
      </el-menu-item>
      <template v-else-if="!validatenull(item[childrenKey])&&vaildRoles(item)">
        <template v-if="isFirstTitle(item)">
          <div class="sidebar-title-section">
            <el-tooltip effect="dark"
                        :disabled="!keyCollapse"
                        :content="generateTitle(item)"
                        placement="right">
              <div>
                {{getCollapseName(generateTitle(item))}}
              </div>
            </el-tooltip>
          </div>
          <template v-for="(child,cindex) in item[childrenKey]" :key="cindex">
            <el-menu-item :index="`${child[pathKey]}-${cindex}`"
                          @click="open(child)"
                          popper-class="airlyle-sidebar"
                          :class="{'is-active':vaildActive(child)}"
                          v-if="validatenull(child[childrenKey])"
                          :key="child[labelKey]">
              <sidebar-icon :icon="child[iconKey]"/>
              <template #title><span>{{generateTitle(child)}}</span></template>
            </el-menu-item>
            <sidebar-item v-else
                          :menu="[child]"
                          :key="cindex"
                          :screen="screen"
                          :collapse="collapse"></sidebar-item>
          </template>
        </template>
        <el-sub-menu v-else
                     popper-class="airlyle-sidebar"
                    :index="item[pathKey]"
                    :key="item[labelKey]">
          <template #title>
            <sidebar-icon :icon="item[iconKey]"/>
            <span>{{generateTitle(item)}}</span>
          </template>
          <template v-for="(child,cindex) in item[childrenKey]" :key="cindex">
            <el-menu-item :index="child[pathKey],cindex"
                          @click="open(child)"
                          popper-class="airlyle-sidebar"
                          :class="{'is-active':vaildActive(child)}"
                          v-if="validatenull(child[childrenKey])"
                          :key="child[labelKey]">
              <sidebar-icon :icon="child[iconKey]"/>
              <template #title><span>{{generateTitle(child)}}</span></template>
            </el-menu-item>
            <sidebar-item v-else
                          :menu="[child]"
                          :key="cindex"
                          :screen="screen"
                          :collapse="collapse"></sidebar-item>
          </template>
        </el-sub-menu>
      </template>
    </template>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {isURL, validatenull} from "@/utils/validate";
import website from '@/config/website';
import SidebarIcon from '@/page/index/sidebar/sidebar-icon.vue'

export default {
  name: "sidebarItem",
  components: { SidebarIcon },
  data() {
    return {
      config: website.menu
    };
  },
  props: {
    menu: {
      type: Array
    },
    screen: {
      type: Number
    },
    first: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["roles","keyCollapse"]),
    labelKey() {
      return this.config.props.label;
    },
    pathKey() {
      return this.config.props.path;
    },
    iconKey() {
      return this.config.props.icon;
    },
    childrenKey() {
      return this.config.props.children;
    },
    isOpenKey() {
      return this.config.props.isOpen;
    },
    nowTagValue() {
      return this.$router.$avueRouter.getValue(this.$route);
    }
  },
  methods: {
    getCollapseName(name){
      if(this.keyCollapse) return name.substring(0,1);
      return name
    },
    getIconType(icon){
      if(icon.includes('iconfont_airlyle')){
        return 'airlyle_icon'
      }else{
        return 'icon'
      }
    },
    // 格式化airlyle菜单图标
    formatIconSvg(iconName){
      if(iconName.includes('iconfont_airlyle')){
        return iconName.replace('iconfont_airlyle ','');
      }
      return iconName
    },
    // 判断路由菜单是否是一级菜单
    isFirstTitle(value){
      return value.parentId === '0';
    },
    generateTitle(item) {
      // 如果备注中有别名的字 则取alias 别名
      if(item.remark && item.remark.indexOf('别名') !== -1){
        if(item.alias)return item.alias
      }
      // 如果没有别名 则取title
      return this.$router.$avueRouter.generateTitle(
        item[this.labelKey],
        (item.meta || {}).i18n
      );
    },
    vaildActive(item) {
      if (this.validIsOpen(item)) {
        return false;
      }
      const groupFlag = (item["group"] || []).some(ele =>
        this.$route.path.includes(ele)
      );
      return this.nowTagValue === item[this.pathKey] || groupFlag;
    },
    vaildRoles(item) {
      item.meta = item.meta || {};
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true;
    },
    validatenull,
    validIsOpen(item) {
      if (item[this.isOpenKey] === 2 && isURL(item[this.pathKey])) {
        return true;
      }
    },
    open(item) {
      if (this.screen <= 1) this.$store.commit("SET_COLLAPSE");
      if (this.validIsOpen(item)) {
        window.open(item[this.pathKey]);
      } else {
        this.$router.$avueRouter.group = item.group;
        this.$router.$avueRouter.meta = item.meta;
        this.$router.push({
          path: this.$router.$avueRouter.getPath({
            name: item[this.labelKey],
            src: item[this.pathKey]
          }, item.meta),
          query: item.query
        });
      }
    }
  }
};
</script>

