<template>
  <div class="avue-contail" :class="{'avue--collapse':isCollapse}">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top ref="top"/>
    </div>
    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar/>
      </div>
      <div class="avue-main" :class="layoutClassName">
        <!-- 顶部标签卡 -->
        <tags/>
        <!--<transition name="fade-scale">-->
        <!--<search class="avue-view" v-show="isSearch"></search>-->
        <!--</transition>-->
        <!-- 主体视图层 -->

<!--        <div class="airlyle-route-view-content" id="avue-view" v-show="!isSearch">-->
<!--          <keep-alive>-->
<!--            <router-view class="avue-view" v-if="$route.meta.keepAlive"/>-->
<!--          </keep-alive>-->
<!--          <router-view class="avue-view" v-if="!$route.meta.keepAlive"/>-->
<!--        </div>-->
        <div class="airlyle-route-view-content" id="avue-view" v-show="!isSearch">
            <router-view class="avue-view" v-slot="{ Component }">
<!--                <transition name="fade-transform" mode="out-in">-->
<!--                    <keep-alive :include="tagsKeep">-->
<!--                    <keep-alive :include="tagsKeep" exclude="quotation-result-view">-->
                    <keep-alive  exclude="quotation-result-view">
                        <Component :is="Component" :key="$route.path"></Component>
                    </keep-alive>
<!--                </transition>-->
            </router-view>
          <keep-alive>

          </keep-alive>
        </div>
      </div>
    </div>
    <div class="avue-shade" @click="showCollapse"></div>
<!--    <guide :dialogShow="guideShowDialog"/>-->
    <guide/>
    <!-- 系统操作手册视频   -->
    <guide-video/>
    <!--  商户角色登录oms系统后如果所属店铺存在没有授权的提示客户重新授权，如果都授权了则不展示  -->
    <!--    <airlyle-notice/>-->
    <!-- 版本更新通知   -->
<!--    <dialog-notice-version/>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tags from "./tags.vue";
import search from "./search.vue";
import breadcrumb from "./breadcrumb/index.vue";
import top from "./top/index.vue";
import sidebar from "./sidebar/index.vue";
import admin from "@/utils/admin";
import { validatenull } from "@/utils/validate";
import { calcDate } from "@/utils/date.js";
import { getStore } from "@/utils/store.js";
import Guide from "@/views/guide/index.vue";
import GuideVideo from "@/views/guide/video.vue";
// import AirlyleNotice from "@/components/dialog/airlyle-notice.vue";
// import DialogNoticeVersion from '@/page/index/notice/dialog-notice-version.vue'

export default {
  components: {
    // DialogNoticeVersion,
    // AirlyleNotice,
    GuideVideo,
    Guide,
    top,
    tags,
    search,
    sidebar,
    breadcrumb,
  },
  name: "index",
  provide() {
    return {
      index: this,
    };
  },
  data() {
    return {
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
    };
  },
  created() {
    //实时检测刷新token
    this.refreshToken();

  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters( [ "isMenu", "isLock", "isCollapse", "website", "menu", "guideIsShow", "guideShow","tagsKeep" ] ),
    // guideShowDialog() {
    //     console.log('!this.guideIsShow',!this.guideIsShow);
    //   // 如果弹过，就不自动弹
    //   if ( !this.guideIsShow ) {
    //     return true;
    //   }
    //     console.log('guideShowDialog',this.guideShow);
    //   return this.guideShow
    //   //this.$store.dispatch('set_guide_show',false)
    // },
    // 过渡 UI调整
    layoutClassName() {

      if ( this.$route.path === '/order/create' ) {
        return 'oms-ui-main oms-ui2-main'
      }

      // if ( this.$route.path === '/order/list'
      //   || this.$route.path === '/oms_product/sku_mapping'
      //   || this.$route.path === '/order/sku_inventory'
      //   || this.$route.path === '/order/sku_inventory_maintenance'
      //   || this.$route.path === '/order/sku_sales_statistics'
      //   || this.$route.path === '/order/cost'
      //   || this.$route.path === '/order/logistics'
      //   || this.$route.path === '/order/inspection'
      //   || this.$route.path === '/order/batch_purchase'
      //   || this.$route.path === '/order/create_order'
      //   || this.$route.path === '/order/bill_list'
      //   || this.$route.path === '/order/order_redirect_list'
      //   || this.$route.path === '/return_label/statistics_track'
      //   || this.$route.path === '/return_label/statistics_sku'
      //   || this.$route.path === '/return_label/statistics'
      //   || this.$route.path === '/workorders/statistics'
      // ) {
      //   return 'oms-ui-main'
      // } else {
      //   return ''
      // }
      return 'oms-ui-main'
    }
  },
  props: [],
  methods: {
    showCollapse() {
      this.$store.commit( "SET_COLLAPSE" );
    },
    // 初始化
    init() {
      this.$store.commit( "SET_SCREEN", admin.getScreen() );
      window.onresize = () => {
        setTimeout( () => {
          this.$store.commit( "SET_SCREEN", admin.getScreen() );
        }, 0 );
      };
      this.$store.dispatch( "FlowRoutes" ).then( () => {
      } );
    },
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch( "GetMenu", item.id ).then( menuRes => {
        let data = menuRes.menu;
        if ( data.length !== 0 ) {
          this.$router.$avueRouter.formatRoutes( data, true );
        }

        //当点击顶部菜单后默认打开第一个菜单
        /*if (!this.validatenull(item)) {
         let itemActive = {},
         childItemActive = 0;
         if (item.path) {
         itemActive = item;
         } else {
         if (this.menu[childItemActive].length === 0) {
         itemActive = this.menu[childItemActive];
         } else {
         itemActive = this.menu[childItemActive].children[childItemActive];
         }
         }
         this.$store.commit('SET_MENU_ID', item);
         this.$router.push({
         path: this.$router.$avueRouter.getPath({
         name: (itemActive.label || itemActive.name),
         src: itemActive.path
         }, itemActive.meta)
         });
         }*/

      } );
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval( () => {
        const token = getStore( {
          name: "token",
          debug: true
        } ) || {};
        const date = calcDate( token.datetime, new Date().getTime() );
        if ( validatenull( date ) ) return;
        if ( date.seconds >= this.website.tokenTime && !this.refreshLock ) {
          this.refreshLock = true;
          this.$store
            .dispatch( "RefreshToken" )
            .then( () => {
              this.refreshLock = false;
            } )
            .catch( () => {
              this.refreshLock = false;
            } );
        }
      }, 10000 );
    }
  }
};
</script>

<style lang="scss" scoped>
.airlyle-route-view-content{
  //height:100%;
  height:calc(100% - $topTagHeight);
  overflow-y:auto;
  overflow-x:hidden;
}
</style>