import { createStore } from 'vuex';

import user from './modules/user'
import common from './modules/common'
import tags from './modules/tags'
import omsProduct from './modules/omsProduct'
import workorder from './modules/workorder';
import logs from './modules/logs'
import dict from './modules/dict'
import guide from './modules/guide'
import businessDict from './modules/businessDict'
import tableColumn from './modules/tableColumn'
import returnLabel from './modules/returnLabel'
import quotation from './modules/quotation/index'
import getters from './getters'
import quotationLogistics from '@/store/modules/quotation/logistics.js'
import storeNameList from '@/store/modules/storeNameList.js'
import ordersList from '@/store/modules/ordersList'


const store = createStore({
  modules: {
    user,
    common,
    logs,
    tags,
    omsProduct,
    workorder,
    dict,
    guide,
    businessDict,
    tableColumn,
    returnLabel,
    // 报价模块
    quotation,
    quotationLogistics,
    storeNameList,
    ordersList
  },
  getters,
});

export default store;
