<template>
  <div class="avue-sidebar">
    <logo></logo>
    <el-scrollbar style="height:100%">
      <div v-if="validatenull(menu)"
           class="avue-sidebar--tip">{{$t('menuTip')}}
      </div>
      <el-menu :default-active="nowTagValue"
               mode="vertical"
               popper-class="airlyle-sidebar"
               :show-timeout="200"
               :collapse="keyCollapse">
        <sidebar-item :menu="menu"
                      :screen="screen"
                      first
                      :collapse="keyCollapse"></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import logo from "../logo.vue";
import sidebarItem from "./sidebarItem.vue";

export default {
  name: "sidebar",
  components: {sidebarItem, logo},
  inject: ["index"],
  data() {
    return {
    };
  },
  created() {
    this.index.openMenu();
  },
  computed: {
    ...mapGetters(["website", "menu", "tag", "keyCollapse", "screen", "menuId"]),
    nowTagValue: function () {
      return this.$router.$avueRouter.getValue(this.$route);
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
</style>

